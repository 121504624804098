$main:#0A6C89;
$main1:#086C80;


.Blogs_Container {

    .rowNormalDiv {
        background-size: 75% 100% !important;
        background-repeat: no-repeat !important;
        height: 35rem;
        -webkit-clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%);
        clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%) !important;

        .col-12 {
            background-image: linear-gradient(to top left, $main1, $main,#838383d8, #cdcdcda0);
            background-size: 100% 100% !important;
            height: 35rem;


            h1 {
                padding: 15rem 0 0rem 10rem;
                font-family: 'Quicksand', sans-serif;
                font-size: 6rem;
                color: white;

            }

         
        }
    }

    .rowNormalDatadiv {

        >.container {
            padding-top: 0rem;
        }
    }

    .descrip {
        >div {
            display: flex;
            background: rgba(255, 255, 255, 0.311);
            margin-top: 1rem;
            border-radius: 10px;
            padding: 2rem;


            .icon_ {
                width: 5rem;
                height: 5rem;
                border-radius: 50%;
                background: linear-gradient(to top left, $main, rgb(197, 197, 197)); 
                text-align: center;

                i {
                    color: white;
                    font-size: 3rem;
                }
            }

            >.text_ {
                width: 80%;
                margin-left: 2rem;

                h6 {
                    font-family: 'Raleway', sans-serif;
                    font-size: 1.4rem;
                    color: $main;
                }

                p {
                    font-family: 'Raleway', sans-serif;
                    font-size: 1.1rem;
                    color: rgba(0, 0, 0, 0.626);
                }
                a{
                    font-family: 'Raleway', sans-serif;
                    font-size: 1rem;
                    text-decoration: none;
                    color: $main;

                }
            }
        }
    }

    .search_divNormal {
        position: absolute;
        top: 20rem;
        right: 5rem;

        >input {
            border: none;
            font-family: 'Raleway', sans-serif;
            box-shadow: none;
            outline: none;
            border-bottom: $main 1px solid;
            width: 25rem;
            font-size: 1.5rem;
        }
    }



    //scrolled
    .rowScrolledDiv {
        z-index: 1;
        transition: all .5s;
        height: 10rem !important;
        width: 90rem;
        -webkit-clip-path: polygon(0 0, 70% 0, 50% 100%, 0 100%);
        clip-path: polygon(0 0, 70% 0, 50% 100%, 0 100%) !important;
        position: fixed;
        top: 0;
        left: 0;

        .col-12 {
            transition: all .5s;
            background:$main;
            background-size: 100% 100% !important;
            height: 10rem;


            h1 {
                transition: all .5s;
                padding: 6.7rem 0 0rem 3rem;
                font-family: 'Quicksand', sans-serif;
                font-size: 2rem;
                color: white;

            }

            p {
                transition: all .5s;
                font-family: 'Quicksand', sans-serif;
                padding: 0rem 0 0rem 3.1rem;
                font-size: 1rem;
                color: white;
                width: 60%;

            }
        }
    }

    .rowScrolledDatadiv {
        >.container {
            padding-top: 40rem;
        }
    }

    .search_divScrolled{
        transition: all .5s;
        z-index: 2;
        position: fixed!important;
        top: 10rem;
        left: 24rem!important;

        >input {
            border: none;
            font-family: 'Raleway', sans-serif;
            box-shadow: none;
            outline: none;
            border-bottom: white 1px solid;
            width: 20rem;
            font-size: 1.5rem;
            background: transparent;
        }
    }
}



@media (max-width:1750px) {

    .Blogs_Container {

        .rowNormalDiv {
            height: 31rem !important;
            -webkit-clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%) !important;
            clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%) !important;

            .col-12 {
                background-image: linear-gradient(to top left, $main, rgba(0, 0, 0, 0.606)) !important;
                background-size: 100% 100% !important;
                height: 31rem !important;


                h1 {
                    padding: 15rem 0 0rem 10rem;
                    font-size: 5.4rem;

                }

                p {
                    padding: 0rem 0 0rem 10.5rem;
                    font-size: 1.3rem;
                }
            }
        }

        .rowScrolledDiv {
            height: 10rem !important;
            width: 70rem;
            -webkit-clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%);
            clip-path: polygon(0 0, 70% 0, 50% 100%, 0 100%) !important;
            top: 0;
            left: 0;

            .col-12 {
                height: 10rem;


                h1 {
                    padding: 7rem 0 0rem 3rem;
                    font-size: 1.7rem;

                }

                p {
                    padding: 0rem 0 0rem 3.1rem;
                    font-size: .95rem;
                }
            }
        }
        .search_divScrolled{
            top: 10rem;
            left: 19rem!important;
    
            >input {
                width: 16rem;
                font-size: 1.5rem;
            }
        }
    }
}

@media (max-width:1400px) {

    .Blogs_Container {

        .rowNormalDiv {
            height: 28rem !important;
            -webkit-clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%);
            clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%) !important;

            .col-12 {
                background-image: linear-gradient(to top left, $main, rgba(0, 0, 0, 0.606)) !important;
                background-size: 100% 100% !important;
                height: 28rem;


                h1 {
                    padding: 12rem 0 0rem 8rem;
                    font-size: 4.7rem;

                }

                p {
                    padding: 0rem 0 0rem 8.3rem;
                    font-size: 1.1rem;
                }
            }
        }

        .rowNormalDatadiv {

            >.container {
                padding-top: 0rem;

            }
        }

        .descrip {
            >div {
                margin-top: 1rem;
                padding: 1.7rem;

                .icon_ {
                    width: 5rem;
                    height: 5rem;

                    i {
                        font-size: 3rem;
                    }
                }

                >.text_ {
                    width: 80%;
                    margin-left: 2rem;

                    h6 {
                        font-size: 1.3rem;
                    }

                    p {
                        font-size: 1rem;
                    }
                }
            }
        }

        .search_divNormal {
            top: 20rem;
            right: 4rem;

            >input {
                width: 20rem;
                font-size: 1.5rem;
            }
        }


        //scrolled
        .rowScrolledDiv {
            height: 9rem !important;
            width: 65rem;
            -webkit-clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%);
            clip-path: polygon(0 0, 70% 0, 50% 100%, 0 100%) !important;

            .col-12 {
                height: 9rem;


                h1 {
                    padding: 6rem 0 0rem 2rem;
                    font-size: 1.7rem;

                }

                p {
                    padding: 0rem 0 0rem 2.1rem;
                    font-size: .9rem;
                }
            }
        }

        .rowScrolledDatadiv {

            >.container {
                padding-top: 35rem;

            }
        }
        .search_divScrolled{
            top: 8rem;
            left: 15.5rem!important;
    
            >input {
                width: 16rem;
                font-size: 1.5rem;
            }
        }
    }
}


@media (max-width:1200px) {

    .Blogs_Container {

        .rowNormalDiv {
            height: 22rem !important;
            -webkit-clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%);
            clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%) !important;

            .col-12 {
                background-image: linear-gradient(to top left, $main, rgba(0, 0, 0, 0.606)) !important;
                background-size: 100% 100% !important;
                height: 22rem;


                h1 {
                    padding: 9rem 0 0rem 5rem;
                    font-size: 4.1rem;

                }

                p {
                    padding: 0rem 0 0rem 5.2rem;
                    font-size: 1rem;
                }
            }
        }

        .rowNormalDatadiv {

            >.container {
                padding-top: 0rem;

            }
        }

        .descrip {
            >div {
                margin-top: 1rem;
                padding: 1.4rem;

                .icon_ {
                    width: 4rem;
                    height: 4rem;

                    i {
                        font-size: 2.5rem;
                    }
                }

                >.text_ {
                    width: 80%;
                    margin-left: 1.7rem;

                    h6 {
                        font-size: 1.2rem;
                    }

                    p {
                        font-size: .9rem;
                    }
                }
            }
        }

        .search_divNormal {
            top: 15rem;
            right: 3rem;

            >input {
                width: 17rem;
                font-size: 1.5rem;
            }
        }

        //scrolled

        .rowScrolledDiv {
            height: 9rem !important;
            width: 65rem;
            -webkit-clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%);
            clip-path: polygon(0 0, 70% 0, 50% 100%, 0 100%) !important;

            .col-12 {
                height: 9rem;


                h1 {
                    padding: 6rem 0 0rem 1rem;
                    font-size: 1.7rem;

                }

                p {
                    padding: 0rem 0 0rem 1rem;
                    font-size: .8rem;
                }
            }
        }

        .rowScrolledDatadiv {

            >.container {
                padding-top: 32rem;

            }
        }
        .search_divScrolled{
            top: 6.6rem;
            left: 15.5rem!important;
    
            >input {
                width: 16rem;
                font-size: 1.3rem;
            }
        }
    }
}


@media (max-width:992px) {

    .Blogs_Container {

        .rowNormalDiv {
            height: 18rem!important;
            -webkit-clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%);
            clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%) !important;

            .col-12 {
                background-image: linear-gradient(to top left, $main, rgba(0, 0, 0, 0.606)) !important;
                background-size: 100% 100% !important;
                height: 18rem!important;


                h1 {
                    padding: 9rem 0 0rem 1rem;
                    font-size: 3.4rem;

                }

                p {
                    padding: 0rem 0 0rem 1rem;
                    font-size: .9rem;
                }
            }
        }

        .rowNormalDatadiv {

            >.container {
                padding-top: 0rem;

            }
        }

        .descrip {
            >div {
                margin-top: 1rem;
                padding: 1rem;

                .icon_ {
                    width: 3rem;
                    height: 3rem;

                    i {
                        font-size: 2rem;
                    }
                }

                >.text_ {
                    width: 80%;
                    margin-left: 1rem;

                    h6 {
                        font-size: 1rem;
                    }

                    p {
                        font-size: .8rem;
                    }
                }
            }
        }

        .search_divNormal {
            top: 12rem;
            right: 2rem;

            >input {
                width: 13rem;
                font-size: 1.3rem;
            }
        }

        //scrolled

        .rowScrolledDiv {
            height:7.6rem !important;
            width: 60rem;
            -webkit-clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%);
            clip-path: polygon(0 0, 70% 0, 50% 100%, 0 100%) !important;

            .col-12 {
                height: 7.6rem;


                h1 {
                    padding: 5rem 0 0rem 1rem;
                    font-size: 1.4rem;

                }

                p {
                    padding: 0rem 0 0rem 1rem;
                    font-size: .75rem;
                }
            }
        }

        .rowScrolledDatadiv {

            >.container {
                padding-top: 30rem;

            }
        }
        .search_divScrolled{
            top: 5.9rem;
            left: 15rem!important;
    
            >input {
                width: 13rem;
                font-size: 1.3rem;
            }
        }
    }
}


@media (max-width:768px) {

    .Blogs_Container {

        .rowNormalDiv {
            height: 16rem !important;
            -webkit-clip-path: polygon(0 0, 90% 0, 55% 100%, 0 100%);
            clip-path: polygon(0 0, 90% 0, 65% 100%, 0 100%) !important;
            background-size: 90% 100% !important;

            .col-12 {
                background-image: linear-gradient(to top left, $main, rgba(0, 0, 0, 0.606)) !important;
                height: 16rem;
                background-size: 100% 100%;


                h1 {
                    padding: 9rem 0 0rem .5rem;
                    font-size: 2.7rem;

                }

               
            }
        }

        .rowNormalDatadiv {

            >.container {
                padding-top: 0rem;

            }
        }

        .descrip {
            >div {
                margin-top: 1rem;
                padding: .5rem;

                .icon_ {
                    width: 2.5rem;
                    height: 2.5rem;

                    i {
                        font-size: 1.5rem;
                    }
                }

                >.text_ {
                    width: 85%;
                    margin-left: .7rem;

                    h6 {
                        font-size: .9rem;
                    }

                    p {
                        font-size: .7rem;
                    }
                    a{
                        font-size: .8rem;
                    }
                }
            }
        }

        .search_divNormal {
            top: 15.8rem;
            right: 1rem;

            >input {
                width: 10rem;
                font-size: 1.1rem;
            }
        }

        //scrolled

        .rowScrolledDiv {
            height: 9rem !important;
            width: 60rem;
            -webkit-clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%);
            clip-path: polygon(0 0, 70% 0, 50% 100%, 0 100%) !important;

            .col-12 {
                height: 9rem;


                h1 {
                    padding: 6.3rem 0 0rem 1rem;
                    font-size: 1.7rem;

                }

              
            }
        }

        .rowScrolledDatadiv {

            >.container {
                padding-top: 30rem;

            }
        }
        .search_divScrolled{
            top: 7rem;
            left: 14rem!important;
    
            >input {
                width: 14rem;
                font-size: 1.3rem;
            }
        }
    }
}


@media (max-width:576px) {

    .Blogs_Container {

        .rowNormalDiv {
            height: 15rem !important;
            background-size: 100% 100% !important;
            -webkit-clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%) !important;

            .col-12 {
                background-image: linear-gradient(to top left, $main, rgba(0, 0, 0, 0.606)) !important;
                background-size: 100% 100% !important;
                height: 15rem;


                h1 {
                    padding: 8.3rem 0 0rem 0rem;
                    font-size: 2.4rem;

                }

                p {
                    padding: 0rem 0 0rem 0rem;
                    font-size: .75rem;
                    width: 55%;
                }
            }
        }

        .rowNormalDatadiv {

            >.container {
                padding-top: 0rem;

            }
        }

        .descrip {
            >div {
                margin-top: 1rem;
                padding: .3rem;

                .icon_ {
                    width: 2rem;
                    height: 2rem;

                    i {
                        font-size: 1.2rem;
                    }
                }

                >.text_ {
                    width: 85%;
                    margin-left: .5rem;

                    h6 {
                        font-size: .8rem;
                    }

                    p {
                        font-size: .6rem;
                    }
                }
            }
        }

        .search_divNormal {
            top: 12.5rem;
            right: 11rem;

            >input {
                width: 12rem;
                font-size: 1.1rem;
                background: #0000005d;
                border-radius: 7px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                color: white;
            }
        }

        //scrolled

        .rowScrolledDiv {
            height: 8rem !important;
            width: 100%;
            -webkit-clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%) !important;

            .col-12 {
                height:8rem!important;


                h1 {
                    padding:5.6rem 0 0rem 1rem;
                    font-size: 1.5rem;

                }

                p {
                    padding: 0rem 0 0rem 1rem;
                    font-size: .72rem;
                }
            }
        }

        .rowScrolledDatadiv {

            >.container {
                padding-top: 28rem;

            }
        }
        .search_divScrolled{
            top: 9rem;
            left: 9rem!important;
    
            >input {
                width: 12rem;
                font-size: 1rem;
            }
        }
    }
}

@media (max-width:394px) {

    .Blogs_Container {

        .rowNormalDiv {
            height: 14rem !important;
            background-size: 100% 100% !important;
            -webkit-clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%) !important;

            .col-12 {
                background-image: linear-gradient(to top left, $main, rgba(0, 0, 0, 0.606)) !important;
                background-size: 100% 100% !important;
                height: 14rem;


                h1 {
                    padding: 7.9rem 0 0rem 0rem;
                    font-size: 1.8rem;

                }

                p {
                    padding: 0rem 0 0rem 0rem;
                    font-size: .63rem;
                    width: 65%;
                }
            }
        }

        .rowNormalDatadiv {

            >.container {
                padding-top: 0rem;

            }
        }

        .descrip {
            >div {
                margin-top: 1rem;
                padding: .2rem;

                .icon_ {
                    width: 1.4rem;
                    height: 1.4rem;

                    i {
                        font-size: .9rem;
                    }
                }

                >.text_ {
                    width: 85%;
                    margin-left: .24rem;

                    h6 {
                        font-size: .74rem;
                    }

                    p {
                        font-size: .55rem;
                    }
                    a{
                        font-size: .7rem;
                    }
                }
            }
        }
        .search_divNormal {
            top: 11.5rem;
            right: 1rem;

            >input {
                width: 12rem;
                font-size: 1.1rem;
            }
        }

        //scrolled

        .rowScrolledDiv {
            height: 7rem !important;
            width: 100%;
            -webkit-clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%) !important;

            .col-12 {
                height: 7rem !important;


                h1 {
                    padding: 5rem 0 0rem .7rem;
                    font-size: 1.2rem;

                }

                p {
                    padding: 0rem 0 0rem .7rem;
                    font-size: .69rem;
                    width: 80%;
                }
            }
        }

        .rowScrolledDatadiv {

            >.container {
                padding-top: 15rem;
            }
        }
        .search_divScrolled{
            top: 9rem;
            left: 3rem!important;
    
            >input {
                width: 12rem;
                font-size: 1rem;
            }
        }
    }
}
$main:#0A6C89;


.overview {
    >p {
        background: #00000011;
        padding: 2rem;
        font-family: 'Raleway', sans-serif;
        border-radius: 10px;
    }

    h5 {
        -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
        clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
        padding: 1rem 2rem;
        width: 50%;
        background: linear-gradient(to top left, $main, rgb(170, 170, 170));
        font-family: 'Raleway', sans-serif;
        border-radius: 10px;
        color: white;
        font-size: 2rem;
    }

    >div {
        background: #ffffff49;
        border-radius: 10px;
        padding: 2rem;
        margin-top: 1rem;
        display: flex;

        >div {
            width: 5rem;
            height: 5rem;
            background: rgba(255, 255, 255, 0.47);
            border-radius: 50%;
            text-align: center;
            padding-top: .5rem!important;
            i{
                font-size: 2.5rem;
                color: $main;
            }
        }

        p {
            margin-left: 3rem;
            font-family: 'Raleway', sans-serif;
            font-size: 1.3rem;
            width: 80%;
        }
    }
}





@media (max-width:1400px) {
    
.overview {
    >p {
        padding: 1.7rem;
    }

    h5 {
        -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
        clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
        padding: .8rem 1.7rem;
        width: 50%;
        font-size: 1.8rem;
    }

    >div {
        padding: 1.7rem;
        margin-top: 1rem;

        >div {
            width: 4.5rem;
            height: 4.5rem;
            border-radius: 50%;
            padding-top: .65rem!important;
            i{
                font-size: 2rem;
            }
        }

        p {
            margin-left: 2rem;
            font-size: 1.2rem;
            width: 80%;
        }
    }
}
}

@media (max-width:1200px) {
    
.overview {
    >p {
        padding: 1.5rem;
        font-size: .94rem;
    }

    h5 {
        -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
        clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
        padding: .7rem 1.5rem;
        width: 50%;
        font-size: 1.5rem;
    }

    >div {
        padding: 1.5rem;
        margin-top: 1rem;

        >div {
            width: 3.5rem;
            height: 3.5rem;
            border-radius: 50%;
            padding-top: .5rem!important;
            i{
                font-size: 1.7rem;
            }
        }

        p {
            margin-left: 1.8rem;
            font-size: 1.15rem;
            width: 85%;
        }
    }
}
}
@media (max-width:992px) {
    
.overview {
    >p {
        padding: 1.2rem;
        font-size: .85rem;
    }

    h5 {
        -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
        clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
        padding: .5rem 1.2rem;
        width: 50%;
        font-size: 1.3rem;
    }

    >div {
        padding: 1.5rem;
        margin-top: 1rem;

        >div {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            padding-top: .3rem!important;
            i{
                font-size: 1.5rem;
            }
        }

        p {
            margin-left: 1.5rem;
            font-size: 1rem;
            width: 85%;
        }
    }
}
}
@media (max-width:768px) {
    
.overview {
    >p {
        padding: 1rem;
        font-size: .72rem;
    }

    h5 {
        -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
        clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
        padding: .3rem 1rem;
        width: 60%;
        font-size: 1.2rem;
    }

    >div {
        padding: 1rem;
        margin-top: 1rem;

        >div {
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            padding-top: .2rem!important;
            i{
                font-size: 1rem;
            }
        }

        p {
            margin-left: 1rem;
            font-size: .85rem;
            width: 85%;
        }
    }
}
}
@media (max-width:576px) {
    
.overview {
    >p {
        padding: .5rem;
        font-size: .63rem;
    }

    h5 {
        -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
        clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
        padding: .3rem .5rem;
        width: 60%;
        font-size: 1.1rem;
    }

    >div {
        padding: .5rem;
        margin-top: 1rem;

        >div {
            width: 1.8rem;
            height: 1.8rem;
            border-radius: 50%;
            padding-top: .1rem!important;
            i{
                font-size: .9rem;
            }
        }

        p {
            margin-left: 1rem;
            font-size: .75rem;
            width: 85%;
        }
    }
}
}
@media (max-width:394px) {
    
.overview {
    >p {
        padding: .3rem;
        font-size: .57rem;
    }

    h5 {
        -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
        clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
        padding: .2rem .2rem;
        width: 70%;
        font-size: .9rem;
    }

    >div {
        padding: .5rem;
        margin-top: 1rem;

        >div {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            padding-top: 0rem!important;
            i{
                font-size: .84rem;
            }
        }

        p {
            margin-left: .5rem;
            font-size: .6rem;
            width: 85%;
        }
    }
}
}
.Loading {
  .row {
    .col-12 {
      margin-top: 25%;
    }
  }
}

@media (max-width: 1200px) {
  .Loading {
    .row {
      .col-12 {
        margin-top: 35%;
      }
    }
  }
}

@media (max-width: 992px) {
  .Loading {
    .row {
      .col-12 {
        margin-top: 45%;
      }
    }
  }
}

@media (max-width: 768px) {
  .Loading {
    .row {
      .col-12 {
        margin-top: 55%;
      }
    }
  }
}

@media (max-width: 576px) {
  .Loading {
    .row {
      .col-12 {
        margin-top: 75%;
      }
    }
  }
}

@media (max-width: 394px) {
  .Loading {
    .row {
      .col-12 {
        margin-top: 20rem;
      }
    }
  }
}

$main:#0A6C89;
$main1:#086C80;


.universityTraining_Container {

    .rowNormalDiv {
        background-size: 75% 100% !important;
        background-repeat: no-repeat !important;
        height: 35rem;
        -webkit-clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%);
        clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%) !important;

        .col-12 {
            background-image: linear-gradient(to top left, $main1, $main,#838383d8, #cdcdcda0) !important;
            background-size: 100% 100% !important;
            height: 35rem;


            h1 {
                padding: 15rem 0 0rem 10rem;
                font-family: 'Quicksand', sans-serif;
                font-size: 6rem;
                color: white;

            }


        }
    }

    
    
    
    //scrolled
    .rowScrolledDiv {
        z-index: 1;
        transition: all .5s;
        height: 15rem !important;
        width: 90rem;
        -webkit-clip-path: polygon(0 0, 70% 0, 50% 100%, 0 100%);
        clip-path: polygon(0 0, 70% 0, 50% 100%, 0 100%) !important;
        position: fixed;
        top: 0;
        left: 0;
        
        .col-12 {
            transition: all .5s;
            background:$main!important;
            background-size: 100% 100% !important;
            height: 11rem;
            
            
            h1 {
                transition: all .5s;
                padding: 7rem 0 0rem 1rem;
                font-family: 'Quicksand', sans-serif;
                font-size: 2rem;
                color: white;
                
            }
            
            
        }
    }
    
}
.rowNormalform {
    margin-top: 0rem!important;
}
.rowScrolledform {
    margin-top: 40rem!important;
}


@media (max-width:1750px) {

    .universityTraining_Container {

        .rowNormalDiv {
            height: 31rem !important;
            -webkit-clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%) !important;
            clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%) !important;

            .col-12 {
                background-image: linear-gradient(to top left, $main, rgba(0, 0, 0, 0.606)) !important;
                background-size: 100% 100% !important;
                height: 31rem !important;


                h1 {
                    padding: 15rem 0 0rem 10rem;
                    font-size: 5.4rem;

                }


            }
        }

        .rowScrolledDiv {
            height: 10rem !important;
            width: 70rem;
            -webkit-clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%);
            clip-path: polygon(0 0, 70% 0, 50% 100%, 0 100%) !important;

            .col-12 {
                height: 10rem;


                h1 {
                    padding: 6.5rem 0 0rem 1.5rem;
                    font-size: 1.7rem;

                }


            }
        }
    }
}

@media (max-width:1400px) {

    .universityTraining_Container {

        .rowNormalDiv {
            height: 28rem !important;
            -webkit-clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%);
            clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%) !important;

            .col-12 {
                background-image: linear-gradient(to top left, $main, rgba(0, 0, 0, 0.606)) !important;
                background-size: 100% 100% !important;
                height: 28rem;


                h1 {
                    padding: 12rem 0 0rem 8rem;
                    font-size: 4.7rem;

                }

            }
        }

        
        
        
        //scrolled
        .rowScrolledDiv {
            height: 9rem !important;
            width: 65rem;
            -webkit-clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%);
            clip-path: polygon(0 0, 70% 0, 50% 100%, 0 100%) !important;
            
            .col-12 {
                height: 9rem;
                
                
                h1 {
                    padding: 6rem 0 0rem 1rem;
                    font-size: 1.5rem;
                    
                }
                
                
            }
        }
        
    }
    .rowNormalform {
        margin-top: 0rem;
    }
    .rowScrolledform {
        margin-top: 35rem!important;
        
    }
}


@media (max-width:1200px) {

    .universityTraining_Container {

        .rowNormalDiv {
            height: 22rem !important;
            -webkit-clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%);
            clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%) !important;

            .col-12 {
                background-image: linear-gradient(to top left, $main, rgba(0, 0, 0, 0.606)) !important;
                background-size: 100% 100% !important;
                height: 22rem;


                h1 {
                    padding: 11rem 0 0rem 5rem;
                    font-size: 4.1rem;

                }


            }
        }

        
        //scrolled
        
        .rowScrolledDiv {
            height: 8rem !important;
            width: 65rem;
            -webkit-clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%);
            clip-path: polygon(0 0, 70% 0, 50% 100%, 0 100%) !important;
            
            .col-12 {
                height: 8rem;
                
                h1 {
                    padding: 5.5rem 0 0rem 1rem;
                    font-size: 1.3rem;
                    
                }
                
                
            }
        }
        
    }
    .rowNormalform {
        margin-top: 0rem;
    }
    .rowScrolledform {
        margin-top: 32rem!important;
    }
}


@media (max-width:992px) {
    
    .universityTraining_Container {

        .rowNormalDiv {
            height: 20rem !important;
            -webkit-clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%);
            clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%) !important;

            .col-12 {
                background-image: linear-gradient(to top left, $main, rgba(0, 0, 0, 0.606)) !important;
                background-size: 100% 100% !important;
                height: 20rem;


                h1 {
                    padding: 10rem 0 0rem 1rem;
                    font-size: 3.4rem;

                }


            }
        }

        
        
        
        
        //scrolled
        
        .rowScrolledDiv {
            height: 7rem !important;
            width: 60rem;
            -webkit-clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%);
            clip-path: polygon(0 0, 70% 0, 50% 100%, 0 100%) !important;
            
            .col-12 {
                height: 7rem;
                
                
                h1 {
                    padding: 4.8rem 0 0rem 1rem;
                    font-size: 1.1rem;
                    
                }
                
                
            }
        }
        
    }
    .rowNormalform {
        margin-top: 0rem;
    }
    .rowScrolledform {
        margin-top: 30rem!important;
    }
}


@media (max-width:768px) {
    
    .universityTraining_Container {
        
        .rowNormalDiv {
            height: 18rem !important;
            -webkit-clip-path: polygon(0 0, 90% 0, 55% 100%, 0 100%);
            clip-path: polygon(0 0, 90% 0, 65% 100%, 0 100%) !important;
            background-size: 90% 100% !important;

            .col-12 {
                background-image: linear-gradient(to top left, $main, rgba(0, 0, 0, 0.606)) !important;
                height: 18rem;
                background-size: 100% 100%;


                h1 {
                    padding: 10.5rem 0 0rem .5rem;
                    font-size: 2.5rem;

                }

            }
        }

        
        
        //scrolled
        
        .rowScrolledDiv {
            height: 9rem !important;
            width: 60rem;
            -webkit-clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%);
            clip-path: polygon(0 0, 70% 0, 50% 100%, 0 100%) !important;
            
            .col-12 {
                height: 9rem;
                
                
                h1 {
                    padding: 6.5rem 0 0rem 1rem;
                    font-size: 1.4rem;
                }
                
                
            }
        }
        
    }
    .rowNormalform {
        margin-top: 0rem;
    }
    .rowScrolledform {
        margin-top: 31rem!important;
    }
}


@media (max-width:576px) {

    .universityTraining_Container {

        .rowNormalDiv {
            height: 16rem !important;
            background-size: 100% 100% !important;
            -webkit-clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%) !important;

            .col-12 {
                background-image: linear-gradient(to top left, $main, rgba(0, 0, 0, 0.606)) !important;
                background-size: 100% 100% !important;
                height: 16rem;


                h1 {
                    padding: 9rem 0 0rem 0rem;
                    font-size: 2rem;

                }


            }
        }

        
        
        
        //scrolled
        
        .rowScrolledDiv {
            height: 8rem !important;
            width: 100%;
            -webkit-clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%) !important;
            
            .col-12 {
                height:8rem!important;
                
                
                h1 {
                    padding: 5.6rem 0 0rem 1rem;
                    font-size: 1.4rem;
                    
                }
                
                
            }
        }
        
    }
    .rowNormalform {
        margin-top: 0rem;
    }
    .rowScrolledform {
        margin-top: 28rem!important;
    }
}

@media (max-width:394px) {

    .universityTraining_Container {

        .rowNormalDiv {
            height: 14rem !important;
            background-size: 100% 100% !important;
            -webkit-clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%) !important;

            .col-12 {
                background-image: linear-gradient(to top left, $main, rgba(0, 0, 0, 0.606)) !important;
                background-size: 100% 100% !important;
                height: 14rem;


                h1 {
                    padding: 8rem 0 0rem 0rem;
                    font-size: 1.6rem;

                }


            }
        }

        
        
        
        
        //scrolled
        
        .rowScrolledDiv {
            height: 7rem !important;
            width: 100%;
            -webkit-clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%) !important;
            
            .col-12 {
                height: 7rem !important;
                
                
                h1 {
                    padding:4.8rem 0 0rem .7rem;
                    font-size: 1.3rem;
                    
                }
                
                
            }
        }
        
    }
    .rowNormalform {
        margin-top: 0rem;
    }
    .rowScrolledform {
        margin-top: 15rem!important;
    }
}
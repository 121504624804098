
$main:#0A6C89;
$main1:#086C80;

.course-card {
    background: #ffffff42;

    .img_containerNormal {
        -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
        clip-path: polygon(0 0, 96% 0, 74% 100%, 0% 100%);
        background-size: 100% 100% !important;
        
        // backdrop-filter: blur(5px)!important;

        .bg-div {
            padding-top: 15rem;
            padding-bottom: 7rem;
            background-image: linear-gradient(to top left, $main1, $main,#838383d8, #cdcdcda0);
            background-size: contain;
            position: relative;
            left: -2rem;
            -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);

            .courseHeading {
                font-size: 5.5rem;
                margin-left: 3rem;
                color: rgb(255, 255, 255);
                font-family: 'Raleway', sans-serif;
                width: 72%;

                span {
                    color: black;
                }

            }

            .desc_ {
                -webkit-clip-path: polygon(0 0, 75% 0, 70% 100%, 0% 100%);
                clip-path: polygon(0 0, 75% 0, 70% 100%, 0% 100%);
                background: #ffffffac;
                position: relative;
                margin-left: 8.5rem;
                padding: 1rem 0.5rem;
                top: 3rem;
                left: 0rem;
                border-radius: 5px;
                // box-shadow: inset 3px 5px 12px;

                p {
                    display: block;
                    font-size: 2rem;
                    color: $main;
                    font-family: 'Raleway', sans-serif;
                    width: 70%;
                }

                h5 {
                    font-family: 'Raleway', sans-serif;
                    color: $main;
                    display: inline;
                    margin-left: 3rem;
                }
            }
        }


    }

    .normalInterestedDiv {
        padding-top: 17rem;


        .interested_btn_div {
            transition: all .5s;
            position: sticky;
            top: 7rem !important;

            .interested {
                position: relative;
                right: 4rem;
                font-size: 2.5rem;
                background: none;
                border: $main 1px solid;
                border-radius: 0;
                padding: 1.3rem 3rem;
                color: $main;
                -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
            }

            .interested:hover {
                background: $main;
                color: white;
            }
        }
    }

    .description_Nav_Normal {
        margin-top: 3rem;

        .nav-item {
            font-size: 2rem;

            .nav-link {
                color: $main;
                font-size: 2rem;
                font-weight: 400;
                font-family: 'Raleway', sans-serif;
            }

            .nav-link:hover {
                transform: scale(1.05);
            }

            .nav-link::after {
                content: "";
                display: block;
                height: 2px;
                width: 0%;
                transition: width .3;
            }

            .nav-link:hover::after {
                content: "";
                border-bottom: $main 1px solid;
                transition: width .3s;
                width: 100%;
                color: $main !important;
            }

        }
    }


    .overview_Cont {

        .scrollspy-example {

            .list-item-1normal {
                margin-top: 2rem;
            }

            .list-item-1scrolled {
                margin-top: 40rem;
            }

            >div {

                h4 {
                    -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    padding: 2rem 3rem;
                    background: linear-gradient(to top left, $main, rgb(197, 197, 197));
                    margin-top: 10rem;
                    font-family: 'Raleway', sans-serif;
                    color: rgb(255, 255, 255);
                    font-size: 3rem;
                    border-radius: 10px;
                }
            }
        }
    }


    // scrolled
    .img_containerScrolled {
        z-index: 2;
        transition: all .5s;
        -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
        clip-path: polygon(0 0, 96% 0, 74% 100%, 0% 100%);
        background-size: 100% 100% !important;
        width: 60rem;
        position: fixed !important;
        top: 0rem;
        left: 0rem;
        background: none!important;


        .bg-div {
            width: 100%;
            transition: all .5s !important;
            padding-top: 6rem;
            padding-bottom: .5rem;
            background:$main;
            background-size: contain;
            position: relative;
            left: -2rem;
            -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

            .courseHeading {
                display: inline;
                transition: all .5s;
                font-size: 1.9rem;
                margin-left: 2rem;
                color: white;
                font-family: 'Raleway', sans-serif;
                width: 72%;

                span {
                    color: black;
                }

            }

            .desc_ {
                margin-top: .5rem;
                transition: all .5s;
                background: #ffffffac;
                position: relative;
                margin-left: 0rem;
                padding: .6rem 0.5rem;
                top: 0rem;
                left: 2rem;
                border-radius: 5px;
                // box-shadow: inset 3px 5px 12px;

                p {
                    transition: all .5s;
                    display: block;
                    font-size: 1.4rem;
                    color: $main;
                    font-family: 'Raleway', sans-serif;
                    width: 70%;
                }

                h5 {
                    transition: all .5s;
                    font-family: 'Raleway', sans-serif;
                    color: $main;
                    display: inline;
                    font-size: .9rem;
                    margin-left: 1rem;
                }
            }
        }
    }

    .scrolledInterestedDiv {
        .interested_btn_div {
            z-index: 1;
            position: fixed;
            top: 6.5rem;
            right: 1rem;

            .interested {
                transition: all .7s;
                position: relative;
                right: 4rem;
                font-size: 1.8rem;
                background: none;
                border: $main 1px solid;
                border-radius: 0;
                padding: .3rem 1.5rem;
                color: $main;
                -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
            }

            .interested:hover {
                background: $main;
                color: white;
            }
        }
    }

    .description_Nav_Scrolled {
        background: rgba(255, 255, 255, 0.743);
        z-index: 1;
        border: none;
        outline: none;
        transition: all .5s;
        position: fixed;
        top: 11rem;
        left: 35rem;
        -webkit-clip-path: polygon(20% 0%, 80% 0%, 80% 100%, 20% 100%);
        clip-path: polygon(20% 0%, 80% 0%, 80% 100%, 20% 100%);

        .active {
            background: linear-gradient(to top left, $main, black);
            color: white !important;
        }

        .nav-item {
            transition: all .5s;

            .nav-link {
                transition: all .5s;
                color: $main;
                font-size: 1rem;
                font-weight: 400;
                font-family: 'Raleway', sans-serif;
            }

            .nav-link:hover {
                transform: scale(1.05);
            }

            .nav-link::after {
                content: "";
                display: block;
                height: 2px;
                width: 0%;
                transition: width .3;
            }

            .nav-link:hover::after {
                content: "";
                border-bottom: $main 1px solid;
                transition: width .3s;
                width: 100%;
                color: $main !important;
            }
        }
    }

}








@media (max-width:1750px) {

    .course-card {

        .img_containerNormal {

            .bg-div {
                padding-top: 11rem;
                padding-bottom: 7rem;
                left: -2rem;
                -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);

                .courseHeading {
                    font-size: 4.8rem;
                    margin-left: 3rem;
                }

                .desc_ {
                    -webkit-clip-path: polygon(0 0, 75% 0, 70% 100%, 0% 100%);
                    clip-path: polygon(0 0, 75% 0, 70% 100%, 0% 100%);
                    margin-left: 8.5rem;
                    padding: 1rem 0.5rem;
                    top: 3rem;


                    p {
                        font-size: 1.6rem;

                    }

                    h5 {
                        margin-left: 1.6rem;
                        font-size: 1rem;
                    }
                }

            }

        }

        .normalInterestedDiv {
            padding-top: 12rem;


            .interested_btn_div {
                top: 7rem !important;

                .interested {
                    right: 4rem;
                    font-size: 2.1rem;
                    padding: 1.3rem 3rem;
                    -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                    clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                }
            }
        }


        // scrolled  description Nav

        .description_Nav_Scrolled {
            top: 11rem;
            left: 28rem;
            -webkit-clip-path: polygon(20% 0%, 80% 0%, 80% 100%, 20% 100%);
            clip-path: polygon(20% 0%, 80% 0%, 80% 100%, 20% 100%);

            .nav-item {
                .nav-link {
                    font-size: .94rem;
                }
            }
        }

    }






}








@media (max-width:1400px) {

    .course-card {

        .img_containerNormal {

            .bg-div {
                padding-top: 9rem;
                padding-bottom: 7rem;
                left: -.4rem;
                -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);

                .courseHeading {
                    font-size: 4.2rem;
                    margin-left: 1rem;

                }

                .desc_ {
                    -webkit-clip-path: polygon(0 0, 75% 0, 70% 100%, 0% 100%);
                    clip-path: polygon(0 0, 75% 0, 70% 100%, 0% 100%);
                    margin-left: 4rem;
                    padding: 1rem 0.5rem;
                    top: 2rem;
                    left: 0rem;

                    p {
                        font-size: 1.4rem;
                    }

                    h5 {
                        margin-left: 1rem;
                        font-size: .9rem;
                    }
                }


            }

        }

        .normalInterestedDiv {
            // overflow-x:hidden!important;
            padding-top: 10rem;


            .interested_btn_div {
                top: 7rem !important;

                .interested {
                    right: 3rem;
                    font-size: 1.8rem;
                    background: none;
                    border: $main 1px solid;
                    border-radius: 0;
                    padding: 1.3rem 3rem;
                    color: $main;
                    -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                    clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                }

                .interested:hover {
                    background: $main;
                    color: white;
                }
            }
        }

        .description_Nav_Normal {
            margin-top: 2rem;

            .nav-item {

                .nav-link {
                    font-size: 1.5rem;
                }

            }
        }

        .overview_Cont {

            .scrollspy-example {

                .list-item-1normal {
                    margin-top: 2rem;
                }

                .list-item-1scrolled {
                    margin-top: 35rem;
                }

                >div {

                    h4 {
                        -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                        clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                        padding: 1.5rem 2rem !important;
                        margin-top: 8rem;
                        font-size: 2.5rem;
                    }
                }
            }
        }




        // scrolled
        .img_containerScrolled {
            -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 74% 100%, 0% 100%);
            width: 50rem;


            .bg-div {
                padding-top: 5.5rem;
                padding-bottom: .5rem;
                left: -.9rem;
                -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

                .courseHeading {
                    font-size: 1.4rem;
                    margin-left: .6rem;
                    width: 72%;

                }

                .desc_ {
                    margin-top: .5rem;
                    margin-left: 0rem;
                    padding: .4rem 0.5rem;
                    top: 0rem;
                    left: .6rem;

                    p {
                        font-size: 1rem;
                        width: 70%;
                    }

                    h5 {
                        font-size: .8rem;
                        margin-left: .8rem;
                    }
                }
            }
        }

        .scrolledInterestedDiv {
            .interested_btn_div {
                top: 6rem;
                right: 1rem;

                .interested {
                    right: 2rem;
                    font-size: 1.2rem;
                    padding: .2rem 1rem;
                    -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                    clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                }
            }
        }


        // scrolled  description Nav

        .description_Nav_Scrolled {
            top: 10.3rem;
            left: 19.9rem;
            -webkit-clip-path: polygon(20% 0%, 80% 0%, 80% 100%, 20% 100%);
            clip-path: polygon(20% 0%, 80% 0%, 80% 100%, 20% 100%);

            .nav-item {
                .nav-link {
                    font-size: .9rem;
                }
            }
        }

    }
}



@media (max-width:1200px) {

    .course-card {

        .img_containerNormal {

            .bg-div {
                padding-top: 8rem;
                padding-bottom: 5rem;
                left: -.4rem;
                -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);

                .courseHeading {
                    font-size: 3.5rem;
                    margin-left: .5rem;

                }

                .desc_ {
                    -webkit-clip-path: polygon(0 0, 75% 0, 70% 100%, 0% 100%);
                    clip-path: polygon(0 0, 75% 0, 68.5% 100%, 0% 100%);
                    margin-left: 4rem;
                    padding: 1rem 0.5rem;
                    top: 1.7rem;
                    left: 0rem;

                    p {
                        font-size: 1.2rem;
                    }

                    h5 {
                        margin-left: .9rem;
                        font-size: .8rem;
                    }
                }

            }

        }

        .normalInterestedDiv {
            padding-top: 8rem;

            .interested_btn_div {
                top: 6rem !important;

                .interested {
                    right: 2rem;
                    font-size: 1.6rem;
                    padding: 1rem 2rem;
                    -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                    clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                }
            }
        }

        .description_Nav_Normal {
            margin-top: 2rem;

            .nav-item {

                .nav-link {
                    font-size: 1.27rem;
                }

            }
        }


        .overview_Cont {

            .scrollspy-example {

                .list-item-1normal {
                    margin-top: 2rem;
                }

                .list-item-1scrolled {
                    margin-top: 30rem;
                }

                >div {

                    h4 {
                        -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                        clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                        padding: 1.1rem 1.5rem !important;
                        margin-top: 7rem;
                        font-size: 2.2rem;
                    }
                }
            }
        }



        // scrolled
        .img_containerScrolled {
            -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 74% 100%, 0% 100%);
            width: 40rem;


            .bg-div {
                padding-top: 4.9rem;
                padding-bottom: .3rem;
                left: -.9rem;
                -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

                .courseHeading {
                    font-size: 1.1rem;
                    margin-left: .6rem;
                    width: 72%;

                }

                .desc_ {
                    margin-top: .5rem;
                    padding: .6rem 0.5rem;
                    left: .6rem;
                    line-height: 12px;

                    p {
                        font-size: .85rem;
                        width: 70%;
                    }

                    h5 {
                        font-size: .7rem;
                        margin-left: .6rem;
                    }
                }
            }
        }

        .scrolledInterestedDiv {
            .interested_btn_div {
                top: 5rem;
                right: 0rem;

                .interested {
                    right: 1rem;
                    font-size: 1rem;
                    padding: .1rem 1rem;
                    -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                    clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                }
            }
        }


        // scrolled  description Nav

        .description_Nav_Scrolled {
            top: 8.7rem;
            left: 16.6rem;
            -webkit-clip-path: polygon(20% 0%, 80% 0%, 80% 100%, 20% 100%);
            clip-path: polygon(20% 0%, 80% 0%, 80% 100%, 20% 100%);

            .nav-item {
                .nav-link {
                    font-size: .73rem;
                }
            }
        }

    }
}

@media (max-width:992px) {

    .course-card {

        .img_containerNormal {

            .bg-div {
                padding-top: 7rem;
                padding-bottom: 4rem;
                left: -.4rem;
                -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);

                .courseHeading {
                    font-size: 2.6rem;
                    margin-left: .5rem;

                }

                .desc_ {
                    -webkit-clip-path: polygon(0 0, 75% 0, 70% 100%, 0% 100%);
                    clip-path: polygon(0 0, 75% 0, 68.5% 100%, 0% 100%);
                    margin-left: 1rem;
                    padding: .7rem 0.5rem;
                    top: 1.7rem;
                    left: 0rem;

                    p {
                        font-size: .8rem;
                    }

                    h5 {
                        margin-left: .6rem;
                        font-size: .6rem;
                    }
                }

            }

        }

        .normalInterestedDiv {
            padding-top: 8rem;

            .interested_btn_div {
                top: 5rem !important;

                .interested {
                    right: 2rem;
                    font-size: 1.6rem;
                    padding: 1rem 2rem;
                    -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                    clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                }
            }
        }

        .description_Nav_Normal {
            margin-top: 1.3rem;

            .nav-item {

                .nav-link {
                    font-size: .83rem;
                }

            }
        }

        .overview_Cont {

            .scrollspy-example {

                .list-item-1normal {
                    margin-top: 2rem;
                }

                .list-item-1scrolled {
                    margin-top: 30rem;
                }

                >div {

                    h4 {
                        -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                        clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                        padding: 1rem 1rem !important;
                        margin-top: 6rem;
                        font-size: 1.8rem;
                    }
                }
            }
        }



        // scrolled
        .img_containerScrolled {
            -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 74% 100%, 0% 100%);
            width: 35rem;


            .bg-div {
                padding-top: 4.3rem;
                padding-bottom: .5rem;
                left: -.9rem;
                -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

                .courseHeading {
                    font-size: 1rem;
                    margin-left: .5rem;
                    width: 72%;

                }

                .desc_ {
                    margin-top: .5rem;
                    padding: .4rem .3rem;
                    left: .5rem;
                    line-height: 10px;

                    p {
                        font-size: .75rem;
                        width: 80%;
                    }

                    h5 {
                        font-size: .7rem;
                        margin-left: .5rem;
                    }
                }
            }
        }

        .scrolledInterestedDiv {
            .interested_btn_div {
                top: 4.5rem;
                right: 0rem;

                .interested {
                    right: 1rem;
                    font-size: 1rem;
                    padding: .1rem 1rem;
                    -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                    clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                }
            }
        }

        // scrolled  description Nav

        .description_Nav_Scrolled {
            top: 8rem;
            left: 17.6rem;
            -webkit-clip-path: polygon(10% 0%, 90% 0%, 90% 100%, 10% 100%);
            clip-path: polygon(10% 0%, 90% 0%, 90% 100%, 10% 100%);

            .nav-item {
                .nav-link {
                    font-size: .54rem;
                }
            }
        }

    }
}

@media (max-width:768px) {

    .course-card {

        .img_containerNormal {

            .bg-div {
                padding-top: 9rem;
                padding-bottom: 4rem;
                left: -.4rem;
                -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);

                .courseHeading {
                    font-size: 3.5rem;
                    margin-left: 2rem;

                }

                .desc_ {
                    -webkit-clip-path: polygon(0 0, 75% 0, 70% 100%, 0% 100%);
                    clip-path: polygon(0 0, 74% 0, 68.5% 100%, 0% 100%);
                    margin-left: 1rem;
                    padding: .7rem 0.5rem;
                    top: 1.7rem;
                    left: 0rem;

                    p {
                        font-size: 1.1rem;
                    }

                    h5 {
                        margin-left: .6rem;
                        font-size: .6rem;
                    }
                }


            }

        }

        .normalInterestedDiv {
            padding-top: 1rem;

            .interested_btn_div {
                top: 5rem !important;

                .interested {
                    right: -60%;
                    font-size: 1.6rem;
                    padding: 1rem 2rem;
                    -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                    clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                }
            }
        }

        .description_Nav_Normal {
            margin-top: 1rem;

            .nav-item {

                .nav-link {
                    font-size: .7rem;
                }

            }
        }


        .overview_Cont {

            .scrollspy-example {
                .list-item-1normal {
                    margin-top: 2rem;
                }

                .list-item-1scrolled {
                    margin-top: 31rem;
                }

                >div {

                    h4 {
                        -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                        clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                        padding: .8rem .8rem !important;
                        margin-top: 5rem;
                        font-size: 1.5rem;
                    }
                }
            }
        }


        // scrolled
        .img_containerScrolled {
            -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 74% 100%, 0% 100%);
            width: 30rem;


            .bg-div {
                padding-top: 5.9rem;
                padding-bottom: .5rem;
                left: -.9rem;
                -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

                .courseHeading {
                    font-size: 1rem;
                    margin-left: .6rem;
                    width: 72%;

                }

                .desc_ {
                    margin-top: .5rem;
                    padding: .3rem .3rem;
                    left: .6rem;
                    line-height: 1px!important;

                    p {
                        font-size: .7rem;
                        width: 80%;
                        margin-top: .2rem;
                        margin-left: .3rem;
                    }

                    h5 {
                        font-size: .65rem;
                        margin-left: .5rem;
                    }
                }
            }
        }

        .scrolledInterestedDiv {
            .interested_btn_div {
                top: 6rem;
                right: 0rem;

                .interested {
                    right: 1rem;
                    font-size: .8rem;
                    padding: .01rem .8rem;
                    -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                    clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                }
            }
        }

        // scrolled  description Nav

        .description_Nav_Scrolled {
            top: 11rem;
            left: 0rem;
            -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);

            .nav-item {
                .nav-link {
                    font-size: .6rem;
                }
            }
        }

    }
}

@media (max-width:576px) {

    .course-card {

        .img_containerNormal {

            .bg-div {
                padding-top: 6rem;
                padding-bottom: 3rem;
                left: -.4rem;
                -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);

                .courseHeading {
                    font-size: 2.1rem;
                    margin-left: 3rem;

                }

                .desc_ {
                    -webkit-clip-path: polygon(0 0, 75% 0, 70% 100%, 0% 100%);
                    clip-path: polygon(0 0, 87% 0, 75.5% 100%, 0% 100%);
                    margin-left: 1rem;
                    padding: .7rem 0.5rem;
                    top: 0rem;
                    left: 0rem;

                    p {
                        font-size: .9rem;
                    }

                    h5 {
                        margin-left: .6rem;
                        display: block;
                        font-size: .7rem;
                    }

                }


            }

        }

        .normalInterestedDiv {
            padding-top: 1rem;

            .interested_btn_div {
                top: 5rem !important;

                .interested {
                    right: -30%;
                    font-size: 1.6rem;
                    padding: 1rem 2rem;
                    -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                    clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                }
            }
        }

        .description_Nav_Normal {
            margin-top: .7rem;

            .nav-item {

                .nav-link {
                    font-size: .6rem;
                }

            }
        }

        .overview_Cont {

            .scrollspy-example {
                .list-item-1normal {
                    margin-top: 2rem;
                }

                .list-item-1scrolled {
                    margin-top: 27rem;
                }
                >div {

                    h4 {
                        -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                        clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                        padding: .6rem .6rem !important;
                        margin-top: 4rem;
                        font-size: 1.3rem;
                    }
                }
            }
        }





        // scrolled
        .img_containerScrolled {
            -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 74% 100%, 0% 100%);
            width: 26rem;


            .bg-div {
                padding-top: 4.6rem;
                padding-bottom: .5rem;
                left: -1rem;
                -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

                .courseHeading {
                    font-size: .8rem;
                    margin-left: .5rem;
                    width: 72%;

                }

                .desc_ {
                    margin-top: .5rem;
                    padding: .4rem .3rem;
                    left: 0rem;
                    line-height: 1px !important;

                    p {
                        font-size: .6rem;
                        width: 80%;
                        margin-top: .2rem;
                        margin-left: .3rem;
                    }

                    h5 {
                        font-size: .55rem;
                        margin-left: .5rem;
                    }
                }
            }
        }

        .scrolledInterestedDiv {
            .interested_btn_div {
                top: 5rem;
                right: 0rem;

                .interested {
                    right: .3rem;
                    font-size: .7rem;
                    padding: .3rem .6rem;
                    -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                    clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                }
            }
        }

        // scrolled  description Nav

        .description_Nav_Scrolled {
            top:9.8rem;
            left: 0rem;
            -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);

            .nav-item {
                .nav-link {
                    font-size: .5rem;
                }
            }
        }

    }
}

@media (max-width:394px) {

    .course-card {

        .img_containerNormal {

            .bg-div {
                padding-top: 5rem;
                padding-bottom: 3rem;
                left: -.3rem;
                -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);

                .courseHeading {
                    font-size: 1.6rem;
                    margin-left: 2.5rem;

                }

                .desc_ {
                    -webkit-clip-path: polygon(0 0, 75% 0, 70% 100%, 0% 100%);
                    clip-path: polygon(0 0, 87% 0, 75.5% 100%, 0% 100%);
                    margin-left: 1rem;
                    padding: .7rem 0.5rem;

                    p {
                        font-size: .7rem;
                    }

                    h5 {
                        margin-left: .6rem;
                        display: block;
                        font-size: .6rem;
                    }

                }


            }

        }

        .normalInterestedDiv {
            padding-top: 1rem;

            .interested_btn_div {
                top: 5rem !important;

                .interested {
                    right: -30%;
                    font-size: 1rem;
                    padding: .5rem 1.3rem;
                    -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                    clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                }
            }
        }

        .description_Nav_Normal {
            margin-top: .5rem;

            .nav-item {
                .nav-link {
                    font-size: .5rem;
                }

            }
        }


        .overview_Cont {

            .scrollspy-example {
                .list-item-1normal {
                    margin-top: 2rem;
                }

                .list-item-1scrolled {
                    margin-top: 25rem;
                }
                >div {
                    h4 {
                        -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                        clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                        padding: .3rem .3rem !important;
                        margin-top: 3rem;
                        font-size: 1rem;
                    }
                }
            }
        }







        // scrolled
        .img_containerScrolled {
            -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 74% 100%, 0% 100%);
            width: 300px !important;


            .bg-div {
                padding-top: 4.1rem;
                padding-bottom: .3rem;
                left: -1rem;
                -webkit-clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

                .courseHeading {
                    font-size: .65rem;
                    margin-left: .6rem;
                    width: 72%;

                }

                .desc_ {
                    margin-top: .2rem;
                    padding: .2rem .3rem;
                    left: 0rem;
                    line-height: 0px !important;

                    p {
                        font-size: .5rem;
                        width: 80%;
                        margin-top: .3rem;
                        margin-left: .2rem;
                    }

                    h5 {
                        font-size: .42rem;
                        margin-left: .3rem;
                    }
                }
            }
        }

        .scrolledInterestedDiv {
            .interested_btn_div {
                top: 4.5rem;
                right: .1rem;

                .interested {
                    right: .3rem;
                    font-size: .6rem;
                    padding: .1rem .5rem;
                    -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                    clip-path: polygon(50% 0%, 100% 0, 100% 0, 81% 100%, 0 100%, 0 100%, 19% 0);
                }
            }
        }

        // scrolled  description Nav

        .description_Nav_Scrolled {
            display: none;
            top: 8.5rem;
            left: 0rem;
            -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);

            .nav-item {
                .nav-link {
                    font-size: .44rem;
                }
            }
        }

    }
}




















// $main: #5247ea;
// $main: #7A7EFF;
// $main: #cf621a;
// $main2:rgb(219, 117, 115);
// $main2: #640A89;
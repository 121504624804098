$main:#0A6C89;




.OurBenefits {
    border: none;
    width: 32%;
    margin-left: 1%;
    margin-top: 4rem;
    margin-bottom: 3rem;
    border-radius: 20px;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 29%, 78% 32%, 79% 50%, 64% 33%, 0 27%);
    clip-path: polygon(0% 0%, 100% 0%, 100% 29%, 78% 32%, 79% 50%, 64% 33%, 0 27%);
    // background: -webkit-linear-gradient(to top left, $main , rgb(0, 0, 0), black);
    background: linear-gradient(to top left, $main, rgb(197, 197, 197));




    .card-body {

        .card-title {
            font-size: 2.3rem;
            font-family: 'Hubballi', cursive;
            font-weight: 900;
            color: white;
        }

        .see_how {
            font-size: 1rem;
            color: $main;
            transform: translateX(0%);
        }

        .card-text {
            padding-top: 6rem;
            font-size: 1rem;
            font-family: 'Moulpali', cursive;
            color: #ffffff;
        }
    }
}

.OurBenefits:hover {
    transform: scale(1.05);
    transition: all .6s;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 100%, 75% 100%, 50% 100%, 0 100%);
    clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 100%, 75% 100%, 50% 100%, 0 100%);
    height: calc(100% - 50%) !important;

    .card-body {

        .see_how {
            transition: .4s all;
            transform: translateX(-100%);
        }

        .card-text {
            transition: all .4s;
            padding-top: 0rem;
            color: rgb(255, 255, 255);
        }
    }

}



@media (max-width:1200px) {

    .OurBenefits {
        width: 32%;
        margin-left: 1%;
        margin-top: 4rem;
        margin-bottom: 3rem;
        -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 29%, 78% 32%, 79% 50%, 64% 33%, 0 27%);
        clip-path: polygon(0% 0%, 100% 0%, 100% 29%, 78% 32%, 79% 50%, 64% 33%, 0 27%);

        .card-body {

            .card-title {
                font-size: 2rem;
                font-weight: 400;
            }

            .see_how {
                font-size: .9rem;
            }

            .card-text {
                padding-top: 6rem;
                font-size: .95rem;
                font-weight: 200;
                visibility: hidden;
            }
        }
    }

    .OurBenefits:hover {
        transform: scale(1.05);
        height: calc(100% - 50%) !important;

        .card-text{
            visibility: visible;
        }
    }

}


@media (max-width:992px) {

    .OurBenefits {
        width: 32%;
        margin-left: 1%;
        margin-top: 4rem;
        margin-bottom: 3rem;
        -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 29%, 78% 32%, 79% 50%, 64% 33%, 0 27%);
        clip-path: polygon(0% 0%, 100% 0%, 100% 29%, 78% 32%, 79% 50%, 64% 33%, 0 27%);

        .card-body {

            .card-title {
                font-size: 1.3rem;
                font-weight: 400;
            }

            .see_how {
                font-size: .73rem;
            }

            .card-text {
                padding-top: 3rem;
                font-size: .8rem;
                font-weight: 100;
                visibility: hidden;
            }
        }
    }

    .OurBenefits:hover {
        transform: scale(1.05);
        height: calc(100% - 50%) !important;

        .card-text{
            visibility: visible;
        }
    }

}

@media (max-width:768px) {

    .OurBenefits {
        width: 90%;
        margin-left: 5%;
        margin-top: 2rem;
        margin-bottom: 1rem;
        -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 29%, 78% 32%, 79% 50%, 64% 33%, 0 27%);
        clip-path: polygon(0% 0%, 100% 0%, 100% 29%, 78% 32%, 79% 50%, 64% 33%, 0 27%);

        .card-body {

            .card-title {
                font-size:2.6rem;
                font-weight: 400;
            }

            .see_how {
                font-size: .9rem;
            }

            .card-text {
                padding-top: 4rem;
                font-size: 1rem;
                font-weight: 100;
                visibility: hidden;
            }
        }
    }
    .OurBenefits:hover {
        transform: scale(1.05);
        height: calc(100% - 50%) !important;

        .card-text{
            visibility: visible;
        }
    }

}

@media (max-width:576px) {

    .OurBenefits {
        width: 95%;
        margin-left: 2.5%;
        margin-top: 1rem;
        margin-bottom: 1cm;
        -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 29%, 78% 32%, 79% 50%, 64% 33%, 0 27%);
        clip-path: polygon(0% 0%, 100% 0%, 100% 29%, 78% 32%, 79% 50%, 64% 33%, 0 27%);

        .card-body {

            .card-title {
                font-size:2.1rem;
                font-weight: 400;
            }

            .see_how {
                font-size: .9rem;
            }

            .card-text {
                padding-top: 5rem;
                font-size: .9rem;
                font-weight: 100;
            }
        }
    }
    .OurBenefits:hover {
        transform: scale(1.05);
        height: calc(100% - 50%) !important;

        .card-text{
            visibility: visible;
        }
    }
}

@media (max-width:394px) {

    .OurBenefits {
        width: 98%;
        margin-left: 1%;
        margin-top: .8remrem;
        margin-bottom:.5rem ;
        -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 29%, 78% 32%, 79% 50%, 64% 33%, 0 27%);
        clip-path: polygon(0% 0%, 100% 0%, 100% 29%, 78% 32%, 79% 50%, 64% 33%, 0 27%);

        .card-body {

            .card-title {
                font-size:1.7rem;
            }

            .see_how {
                font-size: .75rem;
            }

            .card-text {
                padding-top: 4rem;
                font-size: .78rem;
            }
        }
    }
    .OurBenefits:hover {
        transform: scale(1.05);
        height: calc(100% - 50%) !important;

        .card-text{
            visibility: visible;
        }
    }
}
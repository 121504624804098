$main:#0A6C89;

::placeholder {
    font-size: 1.4rem;
    font-family: 'Raleway', sans-serif;
    color: $main !important;
}


.ContactForm {
    backdrop-filter: blur(5px);
    background: rgba(255, 255, 255, 0.606);

    .header {
        border: none;
        text-align: center;

        .title {
            font-family: 'Raleway', sans-serif;
            font-size: 2.8rem;
            color: $main;
            margin-top: 8rem;
        }

        
    }

    .body {
        border: none;

        .form-control {
            width: 80%!important;
            margin-left: 20%;
            resize: none;
            background: #ffffff13 !important;
            backdrop-filter: blur(5px);
            margin-top: 2rem;
            padding: 1rem;
            border-radius: 0;
            border: none;
            outline: none;
            border-bottom: $main 1px solid;
            box-shadow: none;
            font-size: 1.5rem;
            color: black;
        }

        p {
            margin-left: 20%;
            font-size: 1rem;
        }

    }

    .footer {
        border: none;
        text-align: center;
        margin-top: 5rem;
        button {
            font-family: 'Raleway', sans-serif;
            
            width: 60%;
            border: $main 1px solid;
            color: $main;
            background: none;
            font-size: 2rem;
        }

        button:hover {
            background: $main;
            color: white;

        }

    }
}



@media (max-width:1750px) {

    ::placeholder {
        font-size: 1.4rem;
    }



    .ContactForm {
        width: 50rem;

        .header {

            .title {
                font-size: 2.5rem;
                
            }

            
        }

        .body {

            .form-control {
                width: 80%!important;
                margin-left: 20%;
                margin-top: 2rem;
                padding: 1rem;
                font-size: 1.5rem;
            }

        }

        .footer {

            button {
                right: 20% !important;
                width: 60%;
                font-size: 2rem;
            }
        }
    }
}



@media (max-width:1400px) {

    ::placeholder {
        font-size: 1.3rem;
    }


    .ContactForm {
        width: 40rem;

        .header {

            .title {
                font-size: 2rem;
            }

            
        }

        .body {

            .form-control {width: 80%!important;
                margin-left: 20%;
                margin-top: 2rem;
                padding: 1rem;
                font-size: 1.2rem;
            }

        }

        .footer {

            button {
                right: 20% !important;
                width: 60%;
                font-size: 1.5rem;
            }
        }
    }
}


@media (max-width:1200px) {

    ::placeholder {
        font-size: 1.1rem;
    }



    .ContactForm {
        width: 35rem;

        .header {

            .title {
                font-size: 1.5rem;
            }

            
        }

        .body {

            .form-control {width: 80%!important;
                margin-left: 20%;
                margin-top: 1.6rem;
                padding: 1rem;
                font-size: 1.1rem;
            }

        }

        .footer {

            button {
                right: 20% !important;
                width: 60%;
                font-size: 1.3rem;
            }
        }
    }
}

@media (max-width:992px) {

    ::placeholder {
        font-size: 1rem;
    }


    .ContactForm {
        width: 30rem;

        .header {

            .title {
                font-size: 1.4rem;
            }

            
        }

        .body {

            .form-control {width: 90%!important;
                margin-left: 15%;
                margin-top: 1.3rem;
                padding: 1rem;
                font-size: 1rem;
            }

        }

        .footer {

            button {
                right: 20% !important;
                width: 60%;
                font-size: 1.1rem;
            }
        }
    }
}



@media (max-width:768px) {

    ::placeholder {
        font-size: .9rem;
    }


    .ContactForm {
        width: 30rem;

        .header {

            .title {
                font-size: 1.2rem;
            }

            
        }

        .body {

            .form-control {width: 90%!important;
                margin-left: 15%;
                margin-top: 1.1rem;
                padding: 1rem;
                font-size: .9rem;
            }

        }

        .footer {

            button {
                right: 20% !important;
                width: 60%;
                font-size: 1.1rem;
            }
        }
    }
}



@media (max-width:576px) {

    ::placeholder {
        font-size: .8rem;
    }



    .ContactForm {
        width: 98vw;
        overflow-x: hidden;

        .header {

            .title {
                font-size: 1rem;
            }

            
        }

        .body {

            .form-control {width: 100%!important;
                margin-left: 0%;
                margin-top: 1.1rem;
                padding: 1rem;
                font-size: .8rem;
            }

            p {
                margin-left: 0;
                font-size: .8rem;
            }

        }

        .footer {

            button {
                right: 20% !important;
                width: 60%;
                font-size: 1rem;
            }
        }
    }
}



@media (max-width:394px) {

    ::placeholder {
        font-size: .7rem;
    }


    .ContactForm {
        width: 100vw;

        .header {

            .title {
                font-size: 1rem;
            }

            
        }

        .body {

            .form-control {width: 100%!important;
                margin-left: 0%;
                margin-top: 1.1rem;
                padding: 1rem;
                font-size: .7rem;
            }

            p {
                font-size: .66rem;
            }

        }

        .footer {

            button {
                right: 0% !important;
                width: 100%;
                font-size: .7rem;
            }
        }
    }
}
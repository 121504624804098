$main:#0A6C89;



.footer {
    margin-top: 15rem;
    margin-bottom: 8rem;
    
    .container {
        border-top: #0A6C89 1px solid;
        padding-top: 3rem;
        

        .row {

            .col-lg-4 {
                p {
                    width: 80%;
                    margin-top: 3rem;
                    font-size: 1.1rem;
                    color: rgba(0, 0, 0, 0.69);
                    font-family: 'Hubballi', cursive;
                    
                }
            }
            
            .Students {
                margin-left: 8%;
                h5{
                    font-family: 'Hubballi', cursive;
                    font-size: 1.7rem!important;
                    margin-bottom: 2rem;
                }
                a{
                    text-decoration: none;
                    p{
                        color: rgba(0, 0, 0, 0.617);
                    }
                    p:hover{
                        color: $main;
                        transition: all .3s;
                    }
                }
                
            }
            
            .Explore {
                h5{
                    font-family: 'Hubballi', cursive;
                    font-size:1.7rem!important;
                    margin-bottom: 2rem;
                    
                }
                a{
                    text-decoration: none;
                    p{
                        color: rgba(0, 0, 0, 0.617);
                    }
                    p:hover{
                        color: $main;
                        transition: all .3s;
                    }
                }
                
                
                
            }
            
            .Get_Contact {
                h5{
                    font-family: 'Hubballi', cursive;
                    font-size: 1.7rem!important;
                    margin-bottom: 2rem;
                }
                a{
                    text-decoration: none;
                    p{
                        color: rgba(0, 0, 0, 0.617);
                    }
                    p:hover{
                        color: $main;
                        transition: all .3s;
                    }
                }

            }
        }
    }
}


@media (max-width:992px) {
    
.footer {
    margin-top: 8rem;
    margin-bottom: 5rem;
    
    .container {
        border-top: #5247eaab 1px solid;
        padding-top: 3rem;
        

        .row {

            .col-lg-4 {
                p {
                    width: 80%;
                    margin-top: 3rem;
                    font-size: 1.1rem;
                    color: rgba(0, 0, 0, 0.69);
                    font-family: 'Hubballi', cursive;
                    
                }
            }
            
            .Students {
                margin-left: 0%;
                h5{
                    font-size: 1.7rem!important;
                    margin-bottom: 2rem;
                }
                
            }
            
            .Explore {
                margin-top: 2rem;
                h5{
                    font-size:1.7rem!important;
                    margin-bottom: 2rem;
                    
                }
                
                
                
                
            }
            
            .Get_Contact {
                margin-top: 2rem;
                h5{
                    font-size: 1.7rem!important;
                    margin-bottom: 2rem;
                }


            }
        }
    }
}

}

@media (max-width:768px) {
    
.footer {
    margin-top: 8rem;
    margin-bottom: 5rem;
    
    .container {
        border-top: #5247eaab 1px solid;
        padding-top: 3rem;
        

        .row {

            .col-lg-4 {
                p {
                    width: 80%;
                    margin-top: 1.4rem;
                    font-size: 1.1rem;
                    color: rgba(0, 0, 0, 0.69);
                    font-family: 'Hubballi', cursive;
                    
                }
            }
            
            .Students {
                margin-top: 2rem;
                h5{
                    font-size: 1.7rem!important;
                    margin-bottom: 2rem;
                }
                
            }
            
            .Explore {
                margin-top: 2rem;
                h5{
                    font-size:1.7rem!important;
                    margin-bottom: 2rem;
                    
                }
                
                
                
                
            }
            
            .Get_Contact {
                margin-top: 2rem;
                h5{
                    font-size: 1.7rem!important;
                    margin-bottom: 2rem;
                }


            }
        }
    }
}

}
$main:#0A6C89;


.Benefits {
    >div {
        display: flex;
        background: rgba(255, 255, 255, 0.311);
        margin-top: 1rem;
        border-radius: 10px;
        padding: 2rem;


        .icon_ {
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            background: linear-gradient(to top left , $main , black);
            text-align: center;

            i {
                color: white;
                font-size: 3rem;
            }
        }

        >.text_ {
            width: 80%;
            margin-left: 2rem;

            h6 {
                font-family: 'Raleway', sans-serif;
                font-size: 1.4rem;
                color: $main;
            }

            p {
                font-family: 'Raleway', sans-serif;
                font-size: 1.1rem;
                color: rgba(0, 0, 0, 0.626);
            }
        }
    }
}


@media (max-width:1400px) {
    
.Benefits {
    >div {
        margin-top: 1rem;
        padding: 1.7rem;
        .icon_ {
            width: 5rem;
            height: 5rem;
            i {
                font-size: 3rem;
            }
        }

        >.text_ {
            width: 80%;
            margin-left: 2rem;

            h6 {
                font-size: 1.3rem;
            }

            p {
                font-size: 1rem;
            }
        }
    }
}
}

@media (max-width:1200px) {
    
.Benefits {
    >div {
        margin-top: 1rem;
        padding: 1.4rem;
        .icon_ {
            width: 4rem;
            height: 4rem;
            i {
                font-size: 2.5rem;
            }
        }

        >.text_ {
            width: 80%;
            margin-left: 1.7rem;

            h6 {
                font-size: 1.2rem;
            }

            p {
                font-size: .9rem;
            }
        }
    }
}
}

@media (max-width:992px) {
    
.Benefits {
    >div {
        margin-top: 1rem;
        padding: 1rem;
        .icon_ {
            width: 3rem;
            height: 3rem;
            i {
                font-size: 2rem;
            }
        }

        >.text_ {
            width: 80%;
            margin-left: 1rem;

            h6 {
                font-size: 1rem;
            }

            p {
                font-size: .8rem;
            }
        }
    }
}
}

@media (max-width:768px) {
    
.Benefits {
    >div {
        margin-top: 1rem;
        padding: .5rem;
        .icon_ {
            width: 2.5rem;
            height: 2.5rem;
            i {
                font-size: 1.5rem;
            }
        }

        >.text_ {
            width: 85%;
            margin-left: .7rem;

            h6 {
                font-size: .9rem;
            }

            p {
                font-size: .7rem;
            }
        }
    }
}
}

@media (max-width:576px) {
    
.Benefits {
    >div {
        margin-top: 1rem;
        padding: .3rem;
        .icon_ {
            width: 2rem;
            height: 2rem;
            i {
                font-size: 1.2rem;
            }
        }

        >.text_ {
            width: 85%;
            margin-left: .5rem;

            h6 {
                font-size: .8rem;
            }

            p {
                font-size: .6rem;
            }
        }
    }
}
}

@media (max-width:394px) {
    
.Benefits {
    >div {
        margin-top: 1rem;
        padding: .2rem;
        .icon_ {
            width: 1.4rem;
            height: 1.4rem;
            i {
                font-size: .9rem;
            }
        }

        >.text_ {
            width: 85%;
            margin-left: .24rem;

            h6 {
                font-size: .74rem;
            }

            p {
                font-size: .55rem;
            }
        }
    }
}
}

$main:#0A6C89;
$main1:#086C80;

.HireFromUs_Container {

    .rowNormalDiv {
        background-size: 75% 100% !important;
        background-repeat: no-repeat !important;
        height: 35rem;
        -webkit-clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%);
        clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%) !important;

        .col-12 {
            background-image: linear-gradient(to top left, $main1, $main,#838383d8, #cdcdcda0);
            background-size: 100% 100% !important;
            height: 35rem;


            h1 {
                padding: 15rem 0 0rem 10rem;
                font-family: 'Quicksand', sans-serif;
                font-size: 6rem;
                color: white;

            }

            p {
                font-family: 'Quicksand', sans-serif;
                padding: 0rem 0 0rem 10.5rem;
                font-size: 1.5rem;
                color: white;

            }
        }
    }

    .rowNormalDatadiv {

        >.container {
            padding-top: 0rem;

            >h1 {
                -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                padding: 2rem 3rem;
                background: linear-gradient(to top left, $main, rgb(197, 197, 197)); 
                margin-top: 2rem;
                font-family: 'Raleway', sans-serif;
                color: white;
                font-size: 3rem;
                border-radius: 10px;
            }
        }
    }
    .descrip{
        >div {
            display: flex;
            background: rgba(255, 255, 255, 0.311);
            margin-top: 1rem;
            border-radius: 10px;
            padding: 2rem;
    
    
            .icon_ {
                width: 5rem;
                height: 5rem;
                border-radius: 50%;
                background: linear-gradient(to top left, $main, rgb(197, 197, 197));
                text-align: center;
    
                i {
                    color: white;
                    font-size: 3rem;
                }
            }
    
            >.text_ {
                width: 80%;
                margin-left: 2rem;
    
                h6 {
                    font-family: 'Raleway', sans-serif;
                    font-size: 1.4rem;
                    color: $main;
                }
    
                p {
                    font-family: 'Raleway', sans-serif;
                    font-size: 1.1rem;
                    color: rgba(0, 0, 0, 0.626);
                }
            }
        }
    }



    //scrolled
    .rowScrolledDiv {
        z-index: 1;
        transition: all .5s;
        height: 11rem !important;
        width: 90rem;
        -webkit-clip-path: polygon(0 0, 70% 0, 50% 100%, 0 100%);
        clip-path: polygon(0 0, 70% 0, 50% 100%, 0 100%) !important;
        position: fixed;
        top: 0;
        left: 0;

        .col-12 {
            transition: all .5s;
            background: $main;
            background-size: 100% 100% !important;
            height: 11rem;


            h1 {
                transition: all .5s;
                padding: 6.3rem 0 0rem 3rem;
                font-family: 'Quicksand', sans-serif;
                font-size: 2rem;
                color: white;

            }

            p {
                transition: all .5s;
                font-family: 'Quicksand', sans-serif;
                padding: 0rem 0 0rem 3.1rem;
                font-size: .9rem;
                color: white;
                width: 60%;

            }
        }
    }

    .rowScrolledDatadiv {
        >.container {
            padding-top: 30rem;

            >h1 {
                -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                padding: 2rem 3rem;
                background: linear-gradient(to top left, $main, rgb(197, 197, 197))!important;
                margin-top: 10rem;
                font-family: 'Raleway', sans-serif;
                color: white;
                font-size: 3rem;
                border-radius: 10px;
            }
        }
    }
}



@media (max-width:1750px) {

    .HireFromUs_Container {

        .rowNormalDiv {
            height: 31rem !important;
            -webkit-clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%) !important;
            clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%) !important;

            .col-12 {
                background-image: linear-gradient(to top left, $main, rgba(0, 0, 0, 0.606)) !important;
                background-size: 100% 100% !important;
                height: 31rem !important;


                h1 {
                    padding: 15rem 0 0rem 10rem;
                    font-size: 5.4rem;

                }

                p {
                    padding: 0rem 0 0rem 10.5rem;
                    font-size: 1.3rem;
                }
            }
        }

        .rowScrolledDiv {
            height: 10.5rem !important;
            width: 70rem;
            -webkit-clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%);
            clip-path: polygon(0 0, 70% 0, 50% 100%, 0 100%) !important;
            top: 0;
            left: 0;

            .col-12 {
                height: 10.5rem;


                h1 {
                    padding: 6.3rem 0 0rem 3rem;
                    font-size: 1.5rem;

                }

                p {
                    padding: 0rem 0 0rem 3.1rem;
                    font-size: .87rem;
                }
            }
        }
    }
}

@media (max-width:1400px) {

    .HireFromUs_Container {

        .rowNormalDiv {
            height: 28rem !important;
            -webkit-clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%);
            clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%) !important;

            .col-12 {
                background-image: linear-gradient(to top left, $main, rgba(0, 0, 0, 0.606)) !important;
                background-size: 100% 100% !important;
                height: 28rem;


                h1 {
                    padding: 12rem 0 0rem 8rem;
                    font-size: 4.7rem;

                }

                p {
                    padding: 0rem 0 0rem 8.3rem;
                    font-size: 1.1rem;
                }
            }
        }

        .rowNormalDatadiv {

            >.container {
                padding-top: 0rem;

                >h1 {
                    -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    padding: 1.5rem 2rem !important;
                    margin-top: 8rem;
                    font-size: 2.5rem;
                }
            }
        }

        .descrip{
            >div {
                margin-top: 1rem;
                padding: 1.7rem;
                .icon_ {
                    width: 5rem;
                    height: 5rem;
                    i {
                        font-size: 3rem;
                    }
                }
        
                >.text_ {
                    width: 80%;
                    margin-left: 2rem;
        
                    h6 {
                        font-size: 1.3rem;
                    }
        
                    p {
                        font-size: 1rem;
                    }
                }
            }
        }


        //scrolled
        .rowScrolledDiv {
            height: 9.6rem !important;
            width: 65rem;
            -webkit-clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%);
            clip-path: polygon(0 0, 70% 0, 50% 100%, 0 100%) !important;

            .col-12 {
                height: 9.6rem;


                h1 {
                    padding: 5.7rem 0 0rem 2rem;
                    font-size: 1.3rem;

                }

                p {
                    padding: 0rem 0 0rem 2.1rem;
                    font-size: .8rem;
                }
            }
        }

        .rowScrolledDatadiv {

            >.container {
                padding-top: 35rem;

                >h1 {
                    -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    padding: 1.5rem 2rem !important;
                    margin-top: 0rem;
                    font-size: 2.5rem;
                }
            }
        }
    }
}


@media (max-width:1200px) {

    .HireFromUs_Container {

        .rowNormalDiv {
            height: 22rem !important;
            -webkit-clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%);
            clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%) !important;

            .col-12 {
                background-image: linear-gradient(to top left, $main, rgba(0, 0, 0, 0.606)) !important;
                background-size: 100% 100% !important;
                height: 22rem;


                h1 {
                    padding: 9rem 0 0rem 5rem;
                    font-size: 4.1rem;

                }

                p {
                    padding: 0rem 0 0rem 5.2rem;
                    font-size: 1rem;
                }
            }
        }

        .rowNormalDatadiv {

            >.container {
                padding-top: 0rem;

                >h1 {
                    -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    padding: 1.1rem 1.5rem !important;
                    margin-top: 7rem;
                    font-size: 2.2rem;
                }
            }
        }
        .descrip{
            >div {
                margin-top: 1rem;
                padding: 1.4rem;
                .icon_ {
                    width: 4rem;
                    height: 4rem;
                    i {
                        font-size: 2.5rem;
                    }
                }
        
                >.text_ {
                    width: 80%;
                    margin-left: 1.7rem;
        
                    h6 {
                        font-size: 1.2rem;
                    }
        
                    p {
                        font-size: .9rem;
                    }
                }
            }
        }


        //scrolled

        .rowScrolledDiv {
            height: 9.5rem !important;
            width: 65rem;
            -webkit-clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%);
            clip-path: polygon(0 0, 70% 0, 50% 100%, 0 100%) !important;

            .col-12 {
                height: 9.5rem;


                h1 {
                    padding: 5.5rem 0 0rem 1rem;
                    font-size: 1.3rem;

                }

                p {
                    padding: 0rem 0 0rem 1rem;
                    font-size: .8rem;
                }
            }
        }

        .rowScrolledDatadiv {

            >.container {
                padding-top: 32rem;

                >h1 {
                    -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    padding: 1.1rem 1.5rem !important;
                    margin-top: 0rem;
                    font-size: 2.2rem;
                }
            }
        }
    }
}


@media (max-width:992px) {

    .HireFromUs_Container {

        .rowNormalDiv {
            height: 20rem !important;
            -webkit-clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%);
            clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%) !important;

            .col-12 {
                background-image: linear-gradient(to top left, $main, rgba(0, 0, 0, 0.606)) !important;
                background-size: 100% 100% !important;
                height: 20rem;


                h1 {
                    padding: 8.5rem 0 0rem 1rem;
                    font-size: 3.4rem;

                }

                p {
                    padding: 0rem 0 0rem 1rem;
                    font-size: .9rem;
                }
            }
        }

        .rowNormalDatadiv {

            >.container {
                padding-top: 0rem;

                >h1 {
                    -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    padding: 1rem 1rem !important;
                    margin-top: 6rem;
                    font-size: 1.8rem;
                }
            }
        }

        .descrip{
            >div {
                margin-top: 1rem;
                padding: 1rem;
                .icon_ {
                    width: 3rem;
                    height: 3rem;
                    i {
                        font-size: 2rem;
                    }
                }
        
                >.text_ {
                    width: 80%;
                    margin-left: 1rem;
        
                    h6 {
                        font-size: 1rem;
                    }
        
                    p {
                        font-size: .8rem;
                    }
                }
            }
        }


        //scrolled

        .rowScrolledDiv {
            height: 8rem !important;
            width: 60rem;
            -webkit-clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%);
            clip-path: polygon(0 0, 70% 0, 50% 100%, 0 100%) !important;

            .col-12 {
                height: 8rem;


                h1 {
                    padding: 4.7rem 0 0rem 1rem;
                    font-size: 1rem;

                }

                p {
                    padding: 0rem 0 0rem 1rem;
                    font-size: .75rem;
                }
            }
        }

        .rowScrolledDatadiv {

            >.container {
                padding-top: 30rem;

                >h1 {
                    -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    padding: 1rem 1rem !important;
                    margin-top: 0rem;
                    font-size: 1.8rem;
                }
            }
        }
    }
}


@media (max-width:768px) {

    .HireFromUs_Container {

        .rowNormalDiv {
            height: 18rem !important;
            -webkit-clip-path: polygon(0 0, 90% 0, 55% 100%, 0 100%);
            clip-path: polygon(0 0, 90% 0, 65% 100%, 0 100%) !important;
            background-size: 90% 100% !important;

            .col-12 {
                background-image: linear-gradient(to top left, $main, rgba(0, 0, 0, 0.606)) !important;
                height: 18rem;
                background-size: 100% 100%;


                h1 {
                    padding: 8rem 0 0rem .5rem;
                    font-size: 2.5rem;

                }

                p {
                    padding: 0rem 0 0rem .5rem;
                    font-size: .75rem;
                    width: 55%;
                }
            }
        }

        .rowNormalDatadiv {

            >.container {
                padding-top: 0rem;

                >h1 {
                    -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    padding: .8rem .8rem !important;
                    margin-top: 5rem;
                    font-size: 1.5rem;
                }
            }
        }

        .descrip{
            >div {
                margin-top: 1rem;
                padding: .5rem;
                .icon_ {
                    width: 2.5rem;
                    height: 2.5rem;
                    i {
                        font-size: 1.5rem;
                    }
                }
        
                >.text_ {
                    width: 85%;
                    margin-left: .7rem;
        
                    h6 {
                        font-size: .9rem;
                    }
        
                    p {
                        font-size: .7rem;
                    }
                }
            }
        }


        //scrolled

        .rowScrolledDiv {
            height: 10rem !important;
            width: 60rem;
            -webkit-clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%);
            clip-path: polygon(0 0, 70% 0, 50% 100%, 0 100%) !important;

            .col-12 {
                height: 10rem;


                h1 {
                    padding: 6.3rem 0 0rem 1rem;
                    font-size: 1.2rem;

                }

                p {
                    padding: 0rem 0 0rem 1rem;
                    font-size: .7rem;
                }
            }
        }

        .rowScrolledDatadiv {

            >.container {
                padding-top: 30rem;

                >h1 {
                    -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    padding: .8rem .8rem !important;
                    margin-top: 0rem;
                    font-size: 1.5rem;
                }
            }
        }
    }
}


@media (max-width:576px) {

    .HireFromUs_Container {

        .rowNormalDiv {
            height: 16rem !important;
            background-size: 100% 100% !important;
            -webkit-clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%) !important;

            .col-12 {
                background-image: linear-gradient(to top left, $main, rgba(0, 0, 0, 0.606)) !important;
                background-size: 100% 100% !important;
                height: 16rem;


                h1 {
                    padding: 7rem 0 0rem 0rem;
                    font-size: 1.7rem;

                }

                p {
                    padding: 0rem 0 0rem 0rem;
                    font-size: .75rem;
                    width: 55%;
                }
            }
        }

        .rowNormalDatadiv {

            >.container {
                padding-top: 0rem;

                >h1 {
                    -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    padding: .6rem .6rem !important;
                    margin-top: 4rem;
                    font-size: 1.3rem;
                }
            }
        }

        .descrip{
            >div {
                margin-top: 1rem;
                padding: .3rem;
                .icon_ {
                    width: 2rem;
                    height: 2rem;
                    i {
                        font-size: 1.2rem;
                    }
                }
        
                >.text_ {
                    width: 85%;
                    margin-left: .5rem;
        
                    h6 {
                        font-size: .8rem;
                    }
        
                    p {
                        font-size: .6rem;
                    }
                }
            }
        }


        //scrolled

        .rowScrolledDiv {
            height: 10rem !important;
            width: 100%;
            -webkit-clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%) !important;

            .col-12 {
                height: 10rem !important;


                h1 {
                    padding: 5.3rem 0 0rem 1rem;
                    font-size: 1.1rem;

                }

                p {
                    padding: 0rem 0 0rem 1rem;
                    font-size: .7rem;
                }
            }
        }

        .rowScrolledDatadiv {

            >.container {
                padding-top: 28rem;

                >h1 {
                    -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    padding: .6rem .6rem !important;
                    margin-top: 4rem;
                    font-size: 1.3rem;
                }
            }
        }
    }
}

@media (max-width:394px) {

    .HireFromUs_Container {

        .rowNormalDiv {
            height: 14rem !important;
            background-size: 100% 100% !important;
            -webkit-clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%) !important;

            .col-12 {
                background-image: linear-gradient(to top left, $main, rgba(0, 0, 0, 0.606)) !important;
                background-size: 100% 100% !important;
                height: 14rem;


                h1 {
                    padding: 7rem 0 0rem 0rem;
                    font-size: 1.5rem;

                }

                p {
                    padding: 0rem 0 0rem 0rem;
                    font-size: .63rem;
                    width: 65%;
                }
            }
        }

        .rowNormalDatadiv {

            >.container {
                padding-top: 0rem;

                >h1 {
                    -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    padding: .3rem .3rem !important;
                    margin-top: 3rem;
                    font-size: .8rem;
                }
            }
        }

        .descrip{
            >div {
                margin-top: 1rem;
                padding: .2rem;
                .icon_ {
                    width: 1.4rem;
                    height: 1.4rem;
                    i {
                        font-size: .9rem;
                    }
                }
        
                >.text_ {
                    width: 85%;
                    margin-left: .24rem;
        
                    h6 {
                        font-size: .74rem;
                    }
        
                    p {
                        font-size: .55rem;
                    }
                }
            }
        }


        //scrolled

        .rowScrolledDiv {
            height: 8.5rem !important;
            width: 100%;
            -webkit-clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%) !important;

            .col-12 {
                height: 8.5rem !important;


                h1 {
                    padding: 4.7rem 0 0rem .3rem;
                    font-size: 1rem;

                }

                p {
                    padding: 0rem 0 0rem .3rem;
                    font-size: .6rem;
                    width: 80%;
                }
            }
        }

        .rowScrolledDatadiv {

            >.container {
                padding-top: 15rem;

                >h1 {
                    -webkit-clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    clip-path: polygon(100% 0, 84% 50%, 100% 100%, 0 100%, 0 0);
                    padding: .3rem .3rem !important;
                    margin-top: 3rem;
                    font-size: .8rem!important;
                }
            }
        }
    }
}
$main:#0A6C89;


.Curriculum {
    .accordion {


        .accordion-item {
            border-radius: 10px !important;
            margin-top: 1rem;
            background: rgba(255, 255, 255, 0.317) !important;

            .accordion-header {
                border-radius: 10px;


                .accordion-button {
                    border-radius: 10px !important;
                    border: none !important;
                    box-shadow: none;
                    outline: none !important;
                    background: rgba(255, 255, 255, 0.336) !important;
                    font-size: 1.3rem;
                    font-family: 'Raleway', sans-serif;
                    color: $main !important;
                }
            }

            .accordion-collapse {
                border-radius: 10px !important;


                .accordion-body {
                    border-radius: 10px !important;


                    p {
                        font-size: 1.1rem;
                        font-family: 'Raleway', sans-serif;
                        color: rgba(0, 0, 0, 0.679);

                    }

                }
            }
        }
    }
}


@media (max-width:1400px) {

    .Curriculum {
        .accordion {
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        font-size: 1.2rem;
                    }
                }
                .accordion-collapse {
                    .accordion-body {
                        p {
                            font-size: 1.1rem;
                        }

                    }
                }
            }
        }
    }
}

@media (max-width:1200px) {

    .Curriculum {
        .accordion {
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        font-size: 1.1rem;
                    }
                }
                .accordion-collapse {
                    .accordion-body {
                        p {
                            font-size: 1rem;
                        }

                    }
                }
            }
        }
    }
}

@media (max-width:992px) {

    .Curriculum {
        .accordion {
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        font-size: 1rem;
                    }
                }
                .accordion-collapse {
                    .accordion-body {
                        p {
                            font-size: .9rem;
                        }

                    }
                }
            }
        }
    }
}

@media (max-width:768px) {

    .Curriculum {
        .accordion {
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        font-size: .9rem;
                    }
                }
                .accordion-collapse {
                    .accordion-body {
                        p {
                            font-size: .8rem;
                        }

                    }
                }
            }
        }
    }
}
@media (max-width:576px) {

    .Curriculum {
        .accordion {
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        font-size: .8rem;
                    }
                }
                .accordion-collapse {
                    .accordion-body {
                        p {
                            font-size: .7rem;
                        }

                    }
                }
            }
        }
    }
}
@media (max-width:394px) {

    .Curriculum {
        .accordion {
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        font-size: .7rem;
                    }
                }
                .accordion-collapse {
                    .accordion-body {
                        p {
                            font-size: .6rem;
                        }

                    }
                }
            }
        }
    }
}
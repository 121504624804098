$main:#0A6C89;


.Outcome{
    >div{
        display: flex;
        background: rgba(255, 255, 255, 0.353);
        padding: 2rem;
        margin-top: 1rem;
        border-radius: 10px;

        i{
            background: linear-gradient(to top left, $main, black);
            color: white;
            padding: .1rem;
            font-size: 1.2rem;
            border-radius: 50%;
            height: 2rem;
            width: 2rem;
            text-align: center;
        }
        p{
            width:80%;
            font-size: 1.3rem;
            font-family: 'Raleway', sans-serif;
            margin-left: 2rem;
            color: rgba(0, 0, 0, 0.676);
    
        }
    }
}


@media (max-width:1400px) {
    
    .Outcome{
        >div{
            padding: 1.7rem;
            margin-top: 1rem;
    
            i{
                padding: .1rem;
                font-size: 1.2rem;
                height: 2rem;
                width: 2rem;
            }
            p{
                width:85%;
                font-size: 1.1rem;
                margin-left: 1.5rem;
            }
        }
    }
    }
    
    @media (max-width:1200px) {
        
    .Outcome{
        >div{
            padding: 1.3rem;
            margin-top: 1rem;
    
            i{
                padding: .1rem;
                font-size: 1rem;
                height: 1.7rem;
                width: 1.7rem;
            }
            p{
                width:85%;
                font-size: 1rem;
                margin-left: 1rem;
            }
        }
    }
    }
    
    @media (max-width:992px) {
        
    .Outcome{
        >div{
            padding: 1rem;
            margin-top: 1rem;
    
            i{
                padding: 0rem;
                font-size: .9rem;
                height: 1.4rem;
                width: 1.4rem;
            }
            p{
                width:85%;
                font-size: .9rem;
                margin-left: .6rem;
            }
        }
    }
    }
    @media (max-width:768px) {
        
    .Outcome{
        >div{
            padding: .8rem;
            margin-top: 1rem;
    
            i{
                font-size: .77rem;
                height: 1.2rem;
                width: 1.2rem;
            }
            p{
                width:85%;
                font-size: .8rem;
                margin-left: .5rem;
            }
        }
    }
    }
    @media (max-width:576px) {
        
    .Outcome{
        >div{
            padding: .5rem;
            margin-top: 1rem;
    
            i{
                font-size: .69rem;
                height: 1rem;
                width: 1rem;
            }
            p{
                width:85%;
                font-size: .7rem;
                margin-left: .4rem;
            }
        }
    }
    }
    @media (max-width:394px) {
        
    .Outcome{
        >div{
            padding: .3rem;
            margin-top: 1rem;
    
            i{
                font-size: .6rem;
                height: .9rem;
                width: .9rem;
            }
            p{
                width:85%;
                font-size: .6rem;
                margin-left: .2rem;
            }
        }
    }
    }
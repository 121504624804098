
$main:#0A6C89;
$main1:#086C80;


// ---------------
.HomePage {
    width: 100%;
    height: 58rem;
    overflow: hidden !important;
    z-index: -1 !important;

    video {
        object-fit: cover;
        width: 100%;
        height: 58rem;
        z-index: -1 !important;
        position: absolute;
    }

    .Home-text {
        background: rgba(0, 0, 0, 0.394);
        position: relative;
        top: 15rem;
        padding: 7rem 1rem;
        height: 30rem;
        border-radius: 0px;
        border-bottom-left-radius: 0;
        z-index: 1 !important;
        -webkit-clip-path: polygon(0 0, 71% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0 0, 71% 0, 100% 100%, 0% 100%);

        h1 {
            color: white;
            margin-left: 12rem;
            font-family: 'Quicksand', sans-serif;
            font-weight: 400;
            font-size: 6rem;

            span {
                color: $main;
            }
        }

        h3 {
            margin-left: 12rem;
            font-family: 'Quicksand', sans-serif;
            font-weight: 300;
            font-size: 2rem;
            color: white;
        }
    }
}
// take_text
// ---------------
.courses_Container {
    margin-bottom: 5rem;
    background: #fcfcfc;
}

// ---------------
.InteractiveInstitute_OfAnalytics {
    margin-top: 8rem;
    margin-bottom: 5rem;

    .container {
        padding-top: 4rem;
    }

    >h1 {
        margin-left: 5rem;
        display: inline-block;
        font-family: 'Quicksand', sans-serif;
        font-size: 4rem;
        color: $main;
    }

    .heading_div {
        border-radius: 30px;
        margin-left: 1rem;
        display: inline-block;
        background: $main;
        padding: 4rem;
        color: #fcfcfc;
        -webkit-clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%) !important;
        clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%) !important;

        h1 {
            font-family: 'Quicksand', sans-serif;
            font-size: 4rem;
            display: inline-block;
        }
    }

    .para_div {
        border-radius: 10px;
        font-family: 'Quicksand', sans-serif;
        position: relative;
        left: -6.5rem;
        top: 4rem;
        display: inline-block !important;
        padding: 4rem;
        width: 550px !important;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 14.2% 100%);
        background: #00000065 !important;


        p {
            font-size: 1rem;
            color: white;
            display: inline !important;
        }
    }
}

// ---------------
.Benefits_Container {
    margin-top: 6rem;
    padding-top: 2rem;

    .Benefits_Heading_Div {
        background: #00000014;
        position: relative;
        left: -10rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
        width: 50%;
        border-radius: 20px;
        -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
        clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);

        h1 {
            margin-left: 10rem;
            font-size: 4rem;
            font-family: 'Quicksand', sans-serif;
            color: black;
            margin-bottom: 3rem;

            span {
                font-size: 6rem;
                color: $main;
            }
        }
    }

    >.row {
        padding-top: 5rem;
    }
}

// ---------------
.Achievements {
    overflow: hidden;
    margin-top: 3rem;
    margin-bottom: 6rem;


    .achievement_div {
        background: $main;
        width: 65%;
        margin-left: 10rem;
        padding: 8rem;
        border-radius: 0px;
        -webkit-clip-path: polygon(50% 0%, 100% 0, 81% 33%, 100% 70%, 5% 71%, 5% 100%, 0 100%, 0% 35%, 0 0);
        clip-path: polygon(50% 0%, 100% 0, 81% 33%, 100% 70%, 5% 71%, 5% 100%, 0 100%, 0% 35%, 0 0);
    }

    .achievement_h1 {
        z-index: 1 !important;
        position: relative;
        left: 1rem;
        top: -13.5rem;
        font-size: 5rem !important;
        color: #000000;
        font-family: 'Anek Odia', sans-serif;
        font-weight: 600;

        span {
            margin-left: 1rem;
            font-weight: 300;
            color: #fcfcfc;

            i {
                font-weight: 100 !important;
            }
        }

    }

    >p {
        position: relative;
        top: -12rem;
        left: 27rem;
        font-size: 1.5rem;
        color: $main;

        span {
            font-size: 2.3rem;
        }
    }
}

// // ---------------
// .take_text {
//     .row {
//         .heading_div {
//             h1 {
//                 color: $main;
//                 font-size: 5rem;
//                 display: inline;
//                 font-family: 'Raleway', sans-serif;

//                 span {
//                     width: 30rem !important;
//                     padding: 5rem 12rem !important;
//                     -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
//                     clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
//                     background: grey;
//                     color: white;
//                 }
//             }

//             >a {
//                 display: inline;
//                 background: none;
//                 border: $main 1px solid;
//                 color: $main;
//                 border-radius: 0;
//                 font-size: 3rem;
//                 width: auto !important;
//                 padding: 1rem 3rem;
//                 -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
//                 clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
//             }

//             >a:hover {
//                 background: $main;
//                 color: white;
//             }
//         }
//     }
// }



@media (max-width:1737px) {

    .para_div {
        background: #000000ab;
        left: 16rem !important;
        top: -4rem !important;
        display: inline-block !important;
        padding: 2rem 0rem 2rem 4rem !important;
        width: 550px !important;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 10.2% 100%) !important;
        background: #00000065;


        p {
            font-size: .9rem !important;
            color: white;
            display: inline !important;
        }
    }
}



@media (max-width:1400px) {
    .HomePage {
        height: 55rem;

        video {
            height: 55rem;
        }

        .Home-text {
            top: 13rem;
            padding: 7rem 1rem;
            height: 30rem;
            -webkit-clip-path: polygon(0 0, 71% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 71% 0, 100% 100%, 0% 100%);

            h1 {
                margin-left: 10rem;
                font-size: 5.7rem;
            }

            h3 {
                margin-left: 10rem;
                font-size: 1.8rem;
            }
        }
    }


    .InteractiveInstitute_OfAnalytics {
        margin-top: 6rem;
        margin-bottom: 5rem;

        .container {
            padding-top: 4rem;
        }

        >h1 {
            margin-left: 4rem;
            font-size: 3rem;
        }

        .heading_div {
            margin-left: 1rem;
            padding: 3rem;
            -webkit-clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%) !important;
            clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%) !important;

            h1 {
                font-size: 3rem;
            }
        }

        .para_div {
            background: #000000ab;
            left: 12rem !important;
            top: -2rem !important;
            display: inline-block !important;
            padding: 2rem 0rem 2rem 5rem !important;
            width: 500px !important;
            -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 10.2% 100%) !important;
            background: #00000065;


            p {
                font-size: .9rem !important;
                color: white;
                display: inline !important;
            }
        }
    }



    .Benefits_Container {
        margin-top: 6rem;
        padding-top: 2rem;

        .Benefits_Heading_Div {
            left: -7rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
            width: 50%;
            // -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
            // clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);

            h1 {
                margin-left: 7rem;
                font-size: 3.5rem;
                margin-bottom: 3rem;

                span {
                    font-size: 5.5rem;
                }
            }
        }

        >.row {
            padding-top: 5rem;
        }
    }

    .Achievements {
        margin-top: 3rem;
        margin-bottom: 5rem;


        .achievement_div {
            width: 65%;
            margin-left: 6rem;
            padding: 6rem;
            -webkit-clip-path: polygon(50% 0%, 100% 0, 81% 33%, 100% 70%, 5% 71%, 5% 100%, 0 100%, 0% 35%, 0 0);
            clip-path: polygon(50% 0%, 100% 0, 81% 33%, 100% 70%, 5% 71%, 5% 100%, 0 100%, 0% 35%, 0 0);
        }

        .achievement_h1 {
            left: -.5rem;
            top: -10rem;
            font-size: 4rem !important;
            font-family: 'Anek Odia', sans-serif;
            font-weight: 600;

            span {
                margin-left: 1rem;
                font-weight: 300;
                color: #fcfcfc;

                i {
                    font-weight: 100 !important;
                }
            }

        }

        >p {
            top: -10rem;
            left: 22rem;
            font-size: 1.2rem;

            span {
                font-size: 2rem;
            }
        }
    }

    // .take_text {
    //     .row {
    //         .heading_div {
    //             h1 {
    //                 font-size: 4rem;

    //                 span {
    //                     width: 30rem !important;
    //                     padding: 5rem 12rem !important;
    //                 }
    //             }

    //             >a {
    //                 font-size: 2.4rem;
    //                 padding: 1rem 3rem;
    //             }
    //         }
    //     }
    // }

}

@media (max-width:1200px) {
    .HomePage {
        height: 51rem;

        video {
            height: 51rem;

        }

        .Home-text {
            top: 11rem;
            padding: 8rem 1rem;
            height: 30rem;
            -webkit-clip-path: polygon(0 0, 71% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 71% 0, 100% 100%, 0% 100%);

            h1 {
                margin-left: 6rem;
                font-size: 5.1rem;
            }

            h3 {
                margin-left: 6rem;
                font-size: 1.6rem;
            }
        }
    }



    .InteractiveInstitute_OfAnalytics {
        margin-top: 5rem;
        margin-bottom: 5rem;

        .container {
            padding-top: 4rem;
        }

        >h1 {
            margin-left: 2rem;
            font-size: 2.5rem;
        }

        .heading_div {
            margin-left: 1rem;
            padding: 2rem;
            -webkit-clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%) !important;
            clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%) !important;

            h1 {
                font-size: 2.5rem;
            }
        }

        .para_div {
            background: #000000ab;
            left: 5rem !important;
            top: -1.4rem !important;
            padding: 1.4rem 0rem 1.4rem 4rem !important;
            width: 485px !important;
            -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 10.2% 100%) !important;
            background: #00000065;


            p {
                font-size: .8rem !important;
            }
        }
    }


    .Benefits_Container {
        margin-top: 4rem !important;

        .Benefits_Heading_Div {
            left: -3rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
            width: 50%;
            // -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
            // clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);

            h1 {
                margin-left: 3rem;
                font-size: 2.8rem;
                margin-bottom: 3rem;

                span {
                    margin-left: 2.2rem;
                    font-size: 4.8rem;
                }
            }
        }

        >.row {
            padding-top: 5rem;
        }
    }

    // .take_text {
    //     .row {
    //         .heading_div {
    //             h1 {
    //                 font-size: 3.1rem;

    //                 span {
    //                     // width: 2rem!important;
    //                     padding: 4.4rem 9rem !important;
    //                 }
    //             }

    //             >a {
    //                 font-size: 2rem;
    //                 padding: 1rem 3rem;
    //             }
    //         }
    //     }
    // }

}

@media (max-width:992px) {
    .HomePage {
        height: 45rem;

        video {
            height: 45rem;

        }

        .Home-text {
            top: 10rem;
            padding: 8rem 1rem;
            height: 25rem;
            -webkit-clip-path: polygon(0 0, 71% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 71% 0, 100% 100%, 0% 100%);

            h1 {
                margin-left: 3rem;
                font-size: 4.5rem;
            }

            h3 {
                margin-left: 3rem;
                font-size: 1.3rem;
            }
        }
    }
    .top-brands{
        .container{
            .row{
                >div{
                    img{
                        width: 90%;
                    }

                }
            }
        }
    }

    .InteractiveInstitute_OfAnalytics {
        margin-top: 5rem;
        margin-bottom: 5rem;

        .container {
            padding-top: 4rem;
        }

        >h1 {
            margin-left: .4rem;
            font-size: 2rem;
        }

        .heading_div {
            margin-left: 0rem;
            padding: 1.6rem;
            -webkit-clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%) !important;
            clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%) !important;

            h1 {
                font-size: 2rem;
            }
        }

        .para_div {
            left: 1rem !important;
            top: -1.2rem !important;
            padding: 1.1rem 0rem 1.1rem 3rem !important;
            width: 440px !important;
            -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 10.2% 100%) !important;
            background: #00000065;


            p {
                font-size: .74rem !important;
            }
        }
    }

    .Benefits_Container {
        margin-top: 4rem !important;

        .Benefits_Heading_Div {
            left: -2rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
            width: 50%;
            // -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
            // clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);

            h1 {
                margin-left: 1rem;
                font-size: 2.3rem;
                margin-bottom: 2rem;

                span {
                    margin-left: 2.2rem;
                    font-size: 3.7rem;
                }
            }
        }

        >.row {
            padding-top: 3rem;
        }
    }

    .Achievements {
        margin-top: 3rem;
        margin-bottom: 5rem;


        .achievement_div {
            width: 75%;
            margin-left: 5rem;
            padding: 4rem;
            -webkit-clip-path: polygon(50% 0%, 100% 0, 81% 33%, 100% 70%, 5% 71%, 5% 100%, 0 100%, 0% 35%, 0 0);
            clip-path: polygon(50% 0%, 100% 0, 81% 33%, 100% 70%, 5% 71%, 5% 100%, 0 100%, 0% 35%, 0 0);
        }

        .achievement_h1 {
            left: 0rem;
            top: -6.8rem;
            font-size: 3rem !important;

            span {
                margin-left: 0rem;
            }

        }

        >p {
            top: -7.4rem;
            left: 13rem;
            font-size: 1rem;

            span {
                font-size: 1.6rem;
            }
        }
    }

    // .take_text {
    //     .row {
    //         .heading_div {
    //             h1 {
    //                 font-size: 2.6rem;

    //                 span {
    //                     padding: 4rem 7rem !important;
    //                 }
    //             }

    //             >a {
    //                 font-size: 1.6rem;
    //                 padding: 1rem 3rem;
    //             }
    //         }
    //     }
    // }

}

@media (max-width:768px) {
    .HomePage {
        height: 39rem;

        video {
            height: 39rem;

        }

        .Home-text {
            top: 9.5rem;
            padding: 6.5rem 1rem;
            height: 21rem;
            -webkit-clip-path: polygon(0 0, 71% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 71% 0, 100% 100%, 0% 100%);

            h1 {
                margin-left: .5rem;
                font-size: 3.5rem;
            }

            h3 {
                margin-left: .5rem;
                font-size: 1.1rem;
            }
        }
    }


    .InteractiveInstitute_OfAnalytics {
        margin-top: 4rem;
        margin-bottom: 4rem;

        .container {
            padding-top: 4rem;
        }

        >h1 {
            margin-left: .2rem;
            font-size: 1.7rem;
        }

        .heading_div {
            padding: 1.3rem;
            -webkit-clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%) !important;
            clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%) !important;

            h1 {
                font-size: 1.7rem;
            }
        }

        .para_div {
            left: 2 !important;
            top: -1rem !important;
            padding: .9rem 0rem .9rem 2.9rem !important;
            width: 480px !important;
            -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 10.2% 100%) !important;
            background: #00000065;


            p {
                font-size: .73rem !important;
            }
        }
    }

    .Benefits_Container {
        margin-top: 4rem !important;

        .Benefits_Heading_Div {
            left: 0rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
            width: 70%;
            // -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
            // clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);

            h1 {
                margin-left: 1rem;
                font-size: 2.1rem;
                margin-bottom: 2rem;

                span {
                    margin-left: 3.2rem;
                    font-size: 3.7rem;
                }
            }
        }

        >.row {
            padding-top: 3rem;
        }
    }

    .Achievements {
        margin-top: 3rem;
        margin-bottom: 5rem;


        .achievement_div {
            width: 75%;
            margin-left: 3.4rem;
            padding: 2.3rem !important;
            -webkit-clip-path: polygon(50% 0%, 100% 0, 81% 33%, 100% 70%, 5% 71%, 5% 100%, 0 100%, 0% 35%, 0 0);
            clip-path: polygon(50% 0%, 100% 0, 81% 33%, 100% 70%, 5% 71%, 5% 100%, 0 100%, 0% 35%, 0 0);
        }

        .achievement_h1 {
            left: 0rem;
            top: -4rem;
            font-size: 2rem !important;
        }

        >p {
            top: -3rem;
            left: 5.4rem;
            font-size: .94rem;

            span {
                font-size: 1.4rem;
            }
        }
    }

    // .take_text {
    //     .row {
    //         .heading_div {
    //             h1 {
    //                 font-size: 2rem;

    //                 span {
    //                     padding: 2rem 3rem !important;
    //                 }
    //             }

    //             >a {
    //                 font-size: 1rem;
    //                 padding: .5rem 1.2rem;
    //             }
    //         }
    //     }
    // }
}

@media (max-width:576px) {
    .HomePage {
        height: 30rem;

        video {
            height: 30rem;

        }

        .Home-text {
            top: 6.8rem;
            padding: 5.8rem 1rem;
            height: 17rem;
            -webkit-clip-path: polygon(0 0, 71% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 71% 0, 100% 100%, 0% 100%);

            h1 {
                margin-left: 0rem;
                font-size: 2.5rem;
            }

            h3 {
                margin-left: 0rem;
                font-size: .9rem;
            }
        }
    }



    .InteractiveInstitute_OfAnalytics {
        margin-top: 5rem;
        margin-bottom: 4rem;

        .container {
            padding-top: 4rem;
        }

        >h1 {
            margin-left: .1rem;
            font-size: 1.3rem;
        }

        .heading_div {
            padding: 1.1rem;
            -webkit-clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%) !important;
            clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%) !important;

            h1 {
                font-size: 1.5rem;
            }
        }

        .para_div {
            left: 7% !important;
            top: -1rem !important;
            padding: .9rem 0rem .9rem 2.6rem !important;
            width: 90% !important;
            -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 10.2% 100%) !important;
            background: #00000065;


            p {
                font-size: .7rem !important;
            }
        }
    }



    .Benefits_Container {
        margin-top: 0rem !important;

        .Benefits_Heading_Div {
            left: 0rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
            width: 80%;
            // -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
            // clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);

            h1 {
                margin-left: 1rem;
                font-size: 1.7rem;
                margin-bottom: 2rem;

                span {
                    margin-left: 0rem;
                    font-size: 2.8rem;
                }
            }
        }

        >.row {
            padding-top: 02rem;
        }
    }

    .Achievements {
        margin-top: 3rem;
        margin-bottom: 5rem;


        .achievement_div {
            width: 70%;
            margin-left: 2.3rem;
            padding: 2.4rem !important;
            -webkit-clip-path: polygon(50% 0%, 100% 0, 81% 33%, 100% 70%, 5% 71%, 5% 100%, 0 100%, 0% 35%, 0 0);
            clip-path: polygon(50% 0%, 100% 0, 81% 33%, 100% 70%, 5% 71%, 5% 100%, 0 100%, 0% 35%, 0 0);
        }

        .achievement_h1 {
            left: 0rem;
            top: -4rem;
            font-size: 1.4rem !important;
        }

        >p {
            top: -3rem;
            left: 2.4rem;
            font-size: .73rem;

            span {
                font-size: 1.1rem;
            }
        }
    }

    // .take_text {
    //     .row {
    //         .heading_div {
    //             h1 {
    //                 font-size: 1.4rem;

    //                 span {
    //                     padding: 2rem 1.7rem !important;
    //                 }
    //             }

    //             >a {
    //                 font-size: 1rem;
    //                 padding: .5rem .5rem;
    //             }
    //         }
    //     }
    // }

}

@media (max-width:394px) {
    .HomePage {
        height: 26rem;

        video {
            height: 26rem;

        }

        .Home-text {
            top: 7rem;
            padding: 4.5rem 1rem;
            height: 13rem;
            -webkit-clip-path: polygon(0 0, 71% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 71% 0, 100% 100%, 0% 100%);

            h1 {
                font-size: 1.7rem;
            }

            h3 {
                font-size: .75rem;
            }
        }
    }
    .top-brands{
        .container{
            .row{
                >div{
                    width: 33%!important;
                    img{
                        width: 90%;
                    }

                }
            }
        }
    }

    .InteractiveInstitute_OfAnalytics {
        margin-top: 4rem;
        margin-bottom: 4rem;

        .container {
            padding-top: 3rem;
        }

        >h1 {
            margin-left: 0rem;
            font-size: 1.1rem;
        }

        .heading_div {
            padding: 1rem;
            -webkit-clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%) !important;
            clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%) !important;

            h1 {
                font-size: 1.2rem;
            }
        }

        .para_div {
            left: 4% !important;
            top: -.7rem !important;
            padding: .8rem 0rem .8rem 1.8rem !important;
            width: 95% !important;
            -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 10.2% 100%) !important;
            background: #00000065;


            p {
                font-size: .6.7rem !important;
            }
        }
    }





    .Benefits_Container {
        .Benefits_Heading_Div {
            padding-top: 1.6rem;
            padding-bottom: 1.6rem;
            width: 90%;

            h1 {
                margin-left: .4rem;
                font-size: 1.3rem;
                margin-bottom: 1.8rem;

                span {
                    font-size: 2.3rem;
                }
            }
        }

        >.row {
            padding-top: 1.4rem;
        }
    }


    .Achievements {
        margin-top: 2rem;
        margin-bottom: 4rem;


        .achievement_div {
            width: 90%;
            margin-left: 10%;
            padding: 2rem !important;
            -webkit-clip-path: polygon(50% 0%, 100% 0, 81% 33%, 100% 70%, 5% 71%, 5% 100%, 0 100%, 0% 35%, 0 0);
            clip-path: polygon(50% 0%, 100% 0, 81% 33%, 100% 70%, 5% 71%, 5% 100%, 0 100%, 0% 35%, 0 0);
        }

        .achievement_h1 {
            left: .7rem;
            top: -3rem;
            font-size: 1.2rem !important;
        }

        >p {
            top: -1.2rem;
            left: .6rem;
            font-size: .67rem;

            span {
                font-size: .9rem;
            }
        }
    }

    // .take_text {
    //     .row {
    //         .heading_div {
    //             h1 {
    //                 font-size: 1rem;

    //                 span {
    //                     padding: 1.4rem 1.3rem !important;
    //                 }
    //             }

    //             >a {
    //                 font-size: .9rem;
    //                 padding: .5rem .7rem;
    //             }
    //         }
    //     }
    // }

}
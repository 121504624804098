$main:#0A6C89;


::placeholder {
  font-size: 1.4rem;
  font-family: "Raleway", sans-serif;
  color: $main!important;
}

.FormModalInterested {
  background: #0000002d;
  backdrop-filter: blur(5px);

  .modal-dialog {
    position: absolute;
    left: 30rem;

    .modal-content {
      width: 60rem;
      backdrop-filter: blur(5px);
      background: rgba(255, 255, 255, 0.606);

      .modal-header {
        border: none;

        .modal-title {
          font-family: "Raleway", sans-serif;
          font-size: 3rem;
          position: relative !important;
          left: 20% !important;
          color: $main;
        }

        > button {
          font-size: 2rem;
        }
      }

      .modal-body {
        border: none;

        .form-control {
          resize: none;
          background: #ffffff13 !important;
          backdrop-filter: blur(5px);
          margin-top: 2rem;
          padding: 1rem;
          border-radius: 0;
          border: none;
          outline: none;
          border-bottom: $main 1px solid;
          box-shadow: none;
          font-size: 1.5rem;
          color: black;
          margin-left: 10%;
        }
        p {
          margin-left: 10%;
          font-size: 1rem;
        }
      }

      .modal-footer {
        border: none;

        button {
          position: relative !important;
          font-family: "Raleway", sans-serif;
          right: 20% !important;
          width: 60%;
          border: $main 1px solid;
          color: $main;
          background: none;
          font-size: 2rem;
        }

        button:hover {
          background: $main;
          color: white;
        }
      }
    }
  }
}

@media (max-width: 1750px) {
  ::placeholder {
    font-size: 1.4rem;
  }

  .FormModalInterested {
    .modal-dialog {
      left: 20rem;

      .modal-content {
        width: 50rem;

        .modal-header {
          .modal-title {
            font-size: 3rem;
            left: 20% !important;
          }

          > button {
            font-size: 2rem;
          }
        }

        .modal-body {
          .form-control {
            margin-left: 10%;
            margin-top: 2rem;
            padding: 1rem;
            font-size: 1.5rem;
          }
        }

        .modal-footer {
          button {
            right: 20% !important;
            width: 60%;
            font-size: 2rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  ::placeholder {
    font-size: 1.3rem;
  }

  .FormModalInterested {
    .modal-dialog {
      left: 20rem;

      .modal-content {
        width: 40rem;

        .modal-header {
          .modal-title {
            font-size: 2rem;
            left: 20% !important;
          }

          > button {
            font-size: 1.5rem;
          }
        }

        .modal-body {
          .form-control {
            margin-left: 10%;
            margin-top: 2rem;
            padding: 1rem;
            font-size: 1.2rem;
          }
        }

        .modal-footer {
          button {
            right: 20% !important;
            width: 60%;
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  ::placeholder {
    font-size: 1.1rem;
  }

  .FormModalInterested {
    .modal-dialog {
      left: 15rem;

      .modal-content {
        width: 35rem;

        .modal-header {
          .modal-title {
            font-size: 1.5rem;
            left: 20% !important;
          }

          > button {
            font-size: 1.2rem;
          }
        }

        .modal-body {
          .form-control {
            margin-left: 10%;
            margin-top: 1.6rem;
            padding: 1rem;
            font-size: 1.1rem;
          }
        }

        .modal-footer {
          button {
            right: 20% !important;
            width: 60%;
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  ::placeholder {
    font-size: 1rem;
  }

  .FormModalInterested {
    .modal-dialog {
      left: 12rem;

      .modal-content {
        width: 30rem;

        .modal-header {
          .modal-title {
            font-size: 1.4rem;
            left: 20% !important;
          }

          > button {
            font-size: 1rem;
          }
        }

        .modal-body {
          .form-control {
            margin-left: 10%;
            margin-top: 1.3rem;
            padding: 1rem;
            font-size: 1rem;
          }
        }

        .modal-footer {
          button {
            right: 20% !important;
            width: 60%;
            font-size: 1.1rem;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  ::placeholder {
    font-size: 0.9rem;
  }

  .FormModalInterested {
    .modal-dialog {
      left: 5rem;

      .modal-content {
        width: 30rem;

        .modal-header {
          .modal-title {
            font-size: 1.2rem;
            left: 10% !important;
          }

          > button {
            font-size: 1rem;
          }
        }

        .modal-body {
          .form-control {
            margin-left: 10%;
            margin-top: 1.1rem;
            padding: 1rem;
            font-size: 0.9rem;
          }
        }

        .modal-footer {
          button {
            right: 20% !important;
            width: 60%;
            font-size: 1.1rem;
          }
        }
      }
    }
  }
}
@media (max-width: 576px) {
  ::placeholder {
    font-size: 0.8rem;
  }

  .FormModalInterested {
    .modal-dialog {
      left: -1rem;

      .modal-content {
        width: 100vw;

        .modal-header {
          .modal-title {
            font-size: 1rem;
            left: 6% !important;
          }

          > button {
            font-size: 0.9rem;
          }
        }

        .modal-body {
          .form-control {
            margin-left: 0%;
            margin-top: 1.1rem;
            padding: 1rem;
            font-size: 0.8rem;
          }
          p {
            margin-left: 0;
            font-size: 0.8rem;
          }
        }

        .modal-footer {
          button {
            right: 20% !important;
            width: 60%;
            font-size: 1rem;
          }
        }
      }
    }
  }
}
@media (max-width: 394px) {
  ::placeholder {
    font-size: 0.7rem;
  }

  .FormModalInterested {
    .modal-dialog {
      left: -1rem;

      .modal-content {
        width: 100vw;

        .modal-header {
          .modal-title {
            font-size: 1rem;
            left: 6% !important;
          }

          > button {
            font-size: 0.7rem;
          }
        }

        .modal-body {
          .form-control {
            margin-left: 0%;
            margin-top: 1.1rem;
            padding: 1rem;
            font-size: 0.7rem;
          }
          p {
            font-size: 0.66rem;
          }
        }

        .modal-footer {
          button {
            right: 0% !important;
            width: 100%;
            font-size: 0.7rem;
          }
        }
      }
    }
  }
}

$main:#0A6C89;


.IIofAnalyticsCard {
    width: 33% !important;
   
    .card-body {
        text-align: center;
        overflow: hidden!important;

        h6 {
            font-family: 'Quicksand', sans-serif;
            font-size: 1.3rem;
            margin-top: 1rem;

        }

        .explore_link_div {
            margin-top: 2rem;
            transform: scale(0) !important;

            .explore_link {
                font-family: 'Quicksand', sans-serif;
                font-size: 1.2rem;
                color: $main;
                text-decoration: none;
            }
        }
    }
}

.IIofAnalyticsCard:hover {
    .card-body {
        .explore_link_div {
            transition: all .3s;
            transform: scale(1)!important;
            
        }
    }
}



@media (max-width:1200px) {
    .IIofAnalyticsCard {
        width: 33% !important;
       
        .card-body {
            h6 {
                font-size: 1.1rem;
                margin-top: 1rem;
            }
    
            .explore_link_div {
                margin-top: 2rem;
    
                .explore_link {
                    font-size: 1rem;
                }
            }
        }
    }
    
}

@media (max-width:992px) {
    .IIofAnalyticsCard {
        width: 33% !important;
       
        .card-body {
            h6 {
                font-size: .9rem;
                margin-top: 1rem;
            }
    
            .explore_link_div {
                margin-top: 1.3rem;
    
                .explore_link {
                    font-size: .8rem;
                }
            }
        }
    }
    
}

@media (max-width:768px) {
    .IIofAnalyticsCard {
        width: 90% !important;
        margin-left: 5%;
       
        .card-body {
            h6 {
                font-size: 1.4rem;
                margin-top: 1rem;
            }
    
            .explore_link_div {
                margin-top: 2rem;
                margin-bottom: 4rem!important;
    
                .explore_link {
                    font-size: 1.2rem;
                }
            }
        }
    }
    
}

@media (max-width:576px) {
    .IIofAnalyticsCard {
        width: 96% !important;
        margin-left:2%;
       
        .card-body {
            h6 {
                font-size: 1.2rem;
                margin-top: 1rem;
            }
    
            .explore_link_div {
                margin-top: 2rem;
                margin-bottom: 4rem!important;
    
                .explore_link {
                    font-size: 1.04rem;
                }
            }
        }
    }
    
}

@media (max-width:394px) {
    .IIofAnalyticsCard {
        width: 98% !important;
        margin-left:1%;
       
        .card-body {
            h6 {
                font-size: 1rem;
                margin-top: 1rem;
            }
    
            .explore_link_div {
                margin-top: 1.3rem;
                margin-bottom: 4rem!important;
    
                .explore_link {
                    font-size: .9rem;
                }
            }
        }
    }
    
}
$main:#0A6C89;


.achievement {
    padding: 2rem;
    -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 100%, 100% 100%, 50% 81%, 0 100%, 0 100%, 0% 35%, 0 0);
    clip-path: polygon(50% 0%, 100% 0, 100% 100%, 100% 100%, 50% 81%, 0 100%, 0 100%, 0% 35%, 0 0);
    transition: all .5s;
    border: none;
    border-left: $main 1px solid;
    border-radius: 0;
    background: transparent;

    .A_name {
        transition: all .5s;
        font-size: 2.3rem;
        color: $main;
        font-weight: 900;
        font-family: 'Edu NSW ACT Foundation', cursive;
    }

    .A_digit {
        transition: all .5s;
        transform: translateY(210%);
        overflow: hidden;
        font-size: 2rem;
        font-family: 'Edu NSW ACT Foundation', cursive;
        text-align: center;
        color: white;
    }
}

.achievement:hover {
    overflow-y: hidden;
    background: linear-gradient(to top left, $main, rgb(197, 197, 197));


    transform: scale(1.01);
    z-index: 1;

    .A_name {
        transform: translateY(-30%);
        font-size: 1.4rem;
    }

    .A_digit {
        transform: translateY(00%);
    }
}


@media (max-width:1400px) {

    .achievement {
        padding: 2rem;

        .A_name {
            font-size: 1.9rem;
        }

        .A_digit {
            font-size: 1.9rem !important;
        }
    }

    .achievement:hover {

        .A_name {
            font-size: 1.4rem;
        }
    }

}

@media (max-width:1200px) {

    .achievement {
        padding: 2rem;

        .A_name {
            font-size: 1.5rem;
        }

        .A_digit {
            font-size: 1.4rem !important;
        }
    }

    .achievement:hover {

        .A_name {
            font-size: 1.1rem;
        }
    }

}

@media (max-width:992px) {

    .achievement {
        padding: 2rem;

        .A_name {
            font-size: 1rem;
        }

        .A_digit {
            font-size: 1.1rem !important;
        }
    }

    .achievement:hover {

        .A_name {
            font-size: .7rem;
        }
    }

}

@media (max-width:768px) {

    .achievement {
        -webkit-clip-path: polygon(50% 0%, 100% 0, 93% 50%, 100% 100%, 56% 100%, 0 100%, 0 100%, 0% 35%, 0 0);
        clip-path: polygon(50% 0%, 100% 0, 93% 50%, 100% 100%, 56% 100%, 0 100%, 0 100%, 0% 35%, 0 0);
        padding: 2rem;

        .A_name {
            font-size: 1.5rem;
        }

        .A_digit {
            font-size: 1.6rem !important;
        }
    }

    .achievement:hover {

        .A_name {
            font-size: 1.1rem;
        }
    }

}

@media (max-width:576px) {

    .achievement {
        -webkit-clip-path: polygon(50% 0%, 100% 0, 93% 50%, 100% 100%, 56% 100%, 0 100%, 0 100%, 0% 35%, 0 0);
        clip-path: polygon(50% 0%, 100% 0, 93% 50%, 100% 100%, 56% 100%, 0 100%, 0 100%, 0% 35%, 0 0);
        padding: 2rem;

        .A_name {
            font-size: 1.1rem;
        }

        .A_digit {
            font-size: 1.2rem !important;
        }
    }

    .achievement:hover {

        .A_name {
            font-size: .83rem;
        }
    }

}

@media (max-width:394px) {

    .achievement {
        -webkit-clip-path: polygon(50% 0%, 100% 0, 93% 50%, 100% 100%, 56% 100%, 0 100%, 0 100%, 0% 35%, 0 0);
        clip-path: polygon(50% 0%, 100% 0, 93% 50%, 100% 100%, 56% 100%, 0 100%, 0 100%, 0% 35%, 0 0);
        padding: 1.3rem;

        .A_name {
            font-size: .9rem;
        }

        .A_digit {
            font-size: 1rem !important;
        }
    }

    .achievement:hover {

        .A_name {
            font-size: .65rem;
        }
    }

}
$main:#0A6C89;


.Instructor {
  > div {
    margin-top: 1rem;
    display: flex;
    padding: 2rem;
    background: #ffffff83;
    border-radius: 10px;

    .img {
      width: 20%;
      border-radius: 50%;

      img {
        width: 100%;
        border-radius: 50%;
        height: 14.7rem;
      }
    }
    .about {
      margin-left: 2%;
      width: 73%;

      h2 {
        color: $main;
        font-family: "Raleway", sans-serif;
        font-size: 2rem;
      }
      h3 {
        font-family: "Raleway", sans-serif;
        color: rgba(0, 0, 0, 0.754);
        font-size: 1.3rem;
      }
      p {
        color: rgba(0, 0, 0, 0.671);
        font-family: "Raleway", sans-serif;
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 1400px) {
  .Instructor {
    > div {
      margin-top: 1rem;
      padding: 2rem;

      .img {
        width: 22%;
      }
      .about {
        margin-left: 2%;
        width: 73%;

        h2 {
          font-size: 1.7rem;
        }
        h3 {
          font-size: 1.1rem;
        }
        p {
          font-size: 0.9rem;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .Instructor {
    > div {
      margin-top: 1rem;
      padding: 1.3rem;

      .img {
        width: 25%;
      }
      .about {
        margin-left: 2%;
        width: 67%;

        h2 {
          font-size: 1.4rem;
        }
        h3 {
          font-size: 1rem;
        }
        p {
          font-size: 0.8rem;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .Instructor {
    > div {
      margin-top: 1rem;
      padding: 1rem;

      .img {
        width: 32%;
        img {
          height: 13rem !important;
        }
      }
      .about {
        margin-left: 2%;
        width: 67%;

        h2 {
          font-size: 1.2rem;
        }
        h3 {
          font-size: 0.9rem;
        }
        p {
          font-size: 0.7rem;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .Instructor {
    > div {
      margin-top: 1rem;
      padding: 0.9rem;
      display: block;
      text-align: center;
      justify-content: center;
      align-items: center;

      .img {
        width: 100%;
        img {
          width: 50%;
          height: 14rem!important;
        }
      }
      .about {
        width: 95%;
        margin-left: 2.5%;

        h2 {
          margin-top: 1.2rem;
          font-size: 1.5rem;
        }
        h3 {
          font-size: 1rem;
        }
        p {
          font-size: 0.8rem;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .Instructor {
    > div {
      margin-top: 1rem;
      padding: 0.7rem;
      display: block;
      text-align: center;
      justify-content: center;
      align-items: center;

      .img {
        width: 100%;
        img {
          width: 40%;
          height: 9.5rem!important;
        }
      }
      .about {
        width: 98%;
        margin-left: 1%;

        h2 {
          margin-top: 1.2rem;
          font-size: 1.1rem;
        }
        h3 {
          font-size: 0.9rem;
        }
        p {
          font-size: 0.7rem;
        }
      }
    }
  }
}
@media (max-width: 394px) {
  .Instructor {
    > div {
      margin-top: 1rem;
      padding: 0.5rem;

      .img {
        width: 100%;
        img {
          width: 40%;
          height: 7rem!important;
        }
      }
      .about {
        width: 98%;
        margin-left: 1%;

        h2 {
          margin-top: 1rem;
          font-size: 0.9rem;
        }
        h3 {
          font-size: 0.7rem;
        }
        p {
          font-size: 0.55rem;
        }
      }
    }
  }
}

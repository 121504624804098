
/* -------------------------------Scroll Bar------------------------------- */
/* ------------------------------------------------------------------------ */
::-webkit-scrollbar{border-radius: 50px!important;
  width: 7px!important;}
::-webkit-scrollbar-button{color: #0A6C89; height: 0rem;border-radius: 0px;}
::-webkit-scrollbar-thumb{ background:#0A6C89;
   width: 50%!important; 
   height: 10%!important; 
   border-radius: 50px; 
   box-shadow: inset 5px 5px 10px #32c1ed;}
::-webkit-scrollbar-track{background: transparent;}
/* -----------------s------------------------------------------------------- */
/* ------------------------------------------------------------------------ */
